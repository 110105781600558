import { AppBar, Drawer, List, Toolbar } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import BacktestingModule from "./BacktestingModule/BacktestingModule";
import { ReactComponent as Logo } from "../../_assets/logo/logo.svg";
import ActiveTradesModule from "./ActiveTradesModule/ActiveTradesModule";
import Navigation from "./Navigation/Navigation";
import WelfiResultsModule from "./WelfiResultsModule/WelfiResultsModule";
import WelfiCombinationsModule from "./WelfiCombinationsModule/WelfiCombinationsModule";
import Clock from "../../Common/Components/Clock";
import ControllingModule from "./ControllingModule/ControllingModule";

const StyledAppBar = styled(AppBar)`
  z-index: 1;
`;

const StyledLogo = styled(Logo)`
  width: 150px;
  height: auto;
  margin-left: 90px;
`;

const Container = styled.div`
  background-color: #1b3342;
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 88px;
  right: 0;
  z-index: -1;
  padding-left: 12px;
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    max-width: 88px;
    background-color: #15394e;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 64px;
    svg {
      fill: #d4dadc;
      width: 40px;
    }
  }
`;

const TradingDashboardPage = () => {
  return (
    <>
      <BrowserRouter>
        <StyledAppBar position="static">
          <Toolbar>
            <StyledLogo></StyledLogo>
            <Clock></Clock>
          </Toolbar>
        </StyledAppBar>
        <StyledDrawer variant="permanent">
          <List>
            <Navigation></Navigation>
          </List>
        </StyledDrawer>
        <Container>
          <Switch>
            <Route
              exact
              path={["/trading/controlling"]}
              render={() => {
                return <ControllingModule />;
              }}
            />
            <Route
              exact
              path={["/trading/active"]}
              render={() => {
                return <ActiveTradesModule />;
              }}
            />
            <Route
              exact
              path={["/trading/backtests"]}
              render={() => {
                return <BacktestingModule />;
              }}
            />
            <Route
              exact
              path={["/trading/welfi-results"]}
              render={() => {
                return <WelfiResultsModule />;
              }}
            />
            <Route
              exact
              path={["/trading/welfi-combinations"]}
              render={() => {
                return <WelfiCombinationsModule />;
              }}
            />
          </Switch>
        </Container>
      </BrowserRouter>
    </>
  );
};

export default TradingDashboardPage;
