import React from "react";
import Column from "../../../Components/SummaryComponents/Column";
import ColumnHeader from "../../../Components/SummaryComponents/ColumnHeader";
import ColumnBody from "../../../Components/SummaryComponents/ColumnBody";
import ColumnBodyLineGroup from "../../../Components/SummaryComponents/ColumnBodyLineGroup";
import ColumnBodyLine from "../../../Components/SummaryComponents/ColumnBodyLine";
import LabelValuePair from "../../../Components/SummaryComponents/LabelValuePair";
import Label from "../../../Components/SummaryComponents/Label";
import Value from "../../../Components/SummaryComponents/Value";
import formatAmount from "../../../../../../../Common/Functions/formatAmount";
import styled from "styled-components";

const formatPercentage = (value, sign) => {
  const percentageNumber = Math.abs(
    Math.round(value * 10000) / 100
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
  const formattedValue = `${sign}${percentageNumber} %`;
  return formattedValue;
};

const WideValue = styled(Value)`
  width: 220px;
`;

const TradePositionChart = ({ trade, positionDrawingData }) => {
  const signUpside = trade.type.indexOf("OPEN_LONG") === 0 ? "+" : "-";
  const signDownside = trade.type.indexOf("OPEN_SHORT") === 0 ? "+" : "-";
  const baseUpsideColor =
    trade.type.indexOf("OPEN_LONG") === 0 ? "#9bffa3" : "#eb7070";
  const baseDownsideColor =
    trade.type.indexOf("OPEN_SHORT") === 0 ? "#9bffa3" : "#eb7070";
  return (
    <Column>
      <ColumnHeader>Position</ColumnHeader>
      <ColumnBody>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Upside Intrahour</Label>
              <WideValue style={{ color: `${baseUpsideColor}aa` }}>
                {formatPercentage(
                  positionDrawingData.upsidePotentialIntrahourPercentage,
                  signUpside
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.upsidePotentialIntrahour)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Upside Hourly</Label>
              <WideValue style={{ color: `${baseUpsideColor}cc` }}>
                {formatPercentage(
                  positionDrawingData.upsidePotentialPercentage,
                  signUpside
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.upsidePotential)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Upside Realized</Label>
              <WideValue style={{ color: `${baseUpsideColor}` }}>
                {formatPercentage(
                  positionDrawingData.realizedUpsidePotentialPercentage,
                  signUpside
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.realizedUpsidePotential)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Entry Price</Label>
              <WideValue>
                ----- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.entryPrice)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Downside Realized</Label>
              <WideValue style={{ color: `${baseDownsideColor}` }}>
                {formatPercentage(
                  positionDrawingData.realizedDownsidePotentialPercentage,
                  signDownside
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.realizedDownsidePotential)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Downside Hourly</Label>
              <WideValue style={{ color: `${baseDownsideColor}cc` }}>
                {formatPercentage(
                  positionDrawingData.downsidePotentialPercentage,
                  signDownside
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.downsidePotential)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Downside Intrahour</Label>
              <WideValue style={{ color: `${baseDownsideColor}aa` }}>
                {formatPercentage(
                  positionDrawingData.downsidePotentialIntrahourPercentage,
                  signDownside
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatAmount(positionDrawingData.downsidePotentialIntrahour)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
        </ColumnBodyLineGroup>
      </ColumnBody>
    </Column>
  );
};

export default TradePositionChart;
