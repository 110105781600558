import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 100px;
  padding: 12px 33px;
  label {
    width: 19%;
  }
`;

const Title = styled.h3`
  padding: 0px 33px;
  margin-bottom: 0;
`;

const IndicatorSelection = ({
  allIndicators,
  shownIndicators,
  setShownIndicators,
}) => {
  const handleSwitch = (indicator, checked) => {
    let newIndicators = [...new Set(shownIndicators)];
    if (checked) {
      newIndicators.push(indicator);
    } else {
      const index = newIndicators.findIndex((i) => i === indicator);
      newIndicators.splice(index, 1);
    }
    setShownIndicators(newIndicators);
  };

  return (
    <>
      <Title>Technical Indicators</Title>
      <Container>
        {allIndicators
          .sort((a, b) => a.localeCompare(b))
          .map((indicator) => (
            <FormControlLabel
              key={indicator}
              control={
                <Switch
                  checked={shownIndicators.includes(indicator)}
                  onChange={(e) => handleSwitch(indicator, e.target.checked)}
                  name={indicator}
                />
              }
              label={indicator}
            />
          ))}
      </Container>
    </>
  );
};

export default IndicatorSelection;
