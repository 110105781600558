const findHighestOrLowestValueInCandles = (candles, valueType, direction) => {
  const result = candles.reduce((accumulator, current) => {
    if (direction === "highest") {
      return accumulator[valueType] > current[valueType]
        ? accumulator
        : current;
    } else {
      return accumulator[valueType] < current[valueType]
        ? accumulator
        : current;
    }
  }, 0);
  return result[valueType];
};

export default findHighestOrLowestValueInCandles;
