const getSummary = (monthList) => {
  const totalNumberOfTrades = monthList.months
    .map((m) => m.trades.length)
    .reduce((pv, cv) => pv + cv, 0);

  const summaryResults = {
    total: {
      count: totalNumberOfTrades,
      countM: monthList.months.length,
      sum: monthList.sum,
      mean: monthList.mean,
      stdev: monthList.stdev,
      max: monthList.max,
      min: monthList.min,
      win: monthList.win,
      sumM: monthList.sumM,
      meanM: monthList.meanM,
      stdevM: monthList.stdevM,
      maxM: monthList.maxM,
      minM: monthList.minM,
      winM: monthList.winM,
    },
  };
  return summaryResults;
};

export default getSummary;
