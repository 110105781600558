import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ResultAreaContainer from "../Components/ResultAreaContainer";
import TradeListTable from "./Components/TradeListTable";
import constants from "../Constants/constants";
import TradeDetails from "./Components/TradeDetails";

const CANDLES_BEFORE_TRADE = 15;
const CANDLES_AFTER_TRADE = 15;

const Container = styled(ResultAreaContainer)`
  .MuiTableRow-root {
    vertical-align: text-bottom;
  }
  .MuiTableCell-root {
    width: 100px;
  }
`;

let symbolData = {};

const TradeList = ({ tradeList, opposingTradeHandling, strategyId }) => {
  const [shownSymbolData, setShownSymbolData] = useState({});
  const [currentlyShownTrade, setCurrentlyShownTrade] = useState(null);
  const [loadingSymbolData, setLoadingSymbolData] = useState(false);

  const showNextTrade = () => {
    if (currentlyShownTrade === tradeList.length - 1) {
      showTradeDetails(0);
    } else {
      showTradeDetails(currentlyShownTrade + 1);
    }
  };
  const showPreviousTrade = () => {
    if (currentlyShownTrade === 0) {
      showTradeDetails(tradeList.length - 1);
    } else {
      showTradeDetails(currentlyShownTrade - 1);
    }
  };

  const getMonthsInRange = (start, end) => {
    const months = [];
    let current = new Date(start.getTime());
    current.setUTCDate(1);
    while (current.getTime() <= end.getTime()) {
      months.push(new Date(current.getTime()));
      current.setUTCMonth(current.getUTCMonth() + 1);
    }
    return months;
  };

  const getYearMonth = (date) => {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    return `${year}-${month.toString().padStart(2, "0")}`;
  };

  const showTradeDetails = async (index) => {
    setCurrentlyShownTrade(index);
    setLoadingSymbolData(true);
    const trade = tradeList[index];
    const firstShownHour =
      trade.openTime - constants.MS_PER_HOUR * CANDLES_BEFORE_TRADE;
    const lastShownHour =
      trade.closeTime + constants.MS_PER_HOUR * CANDLES_AFTER_TRADE;
    const requiredMonths = getMonthsInRange(
      new Date(firstShownHour),
      new Date(lastShownHour)
    );
    const missingMonths = requiredMonths.filter((month) => {
      const symbolDataForMonth =
        symbolData[trade.symbol]?.[getYearMonth(month)];
      return !symbolDataForMonth;
    });
    if (missingMonths.length) {
      for (const month of missingMonths) {
        const startDate = new Date(month.getTime());
        startDate.setUTCDate(1);
        startDate.setUTCHours(0);
        startDate.setUTCMinutes(0);
        startDate.setUTCSeconds(0);
        const endDate = new Date(month.getTime());
        endDate.setUTCMonth(endDate.getMonth() + 1);
        endDate.setUTCDate(1);
        endDate.setUTCHours(0);
        endDate.setUTCMinutes(0);
        endDate.setUTCSeconds(0);
        const { data: result } = await axios.get(
          `/data-provider/historical-symbol-data-with-indicators?symbol=${
            trade.symbol
          }&startTime=${startDate.getTime()}&endTime=${
            endDate.getTime() - 1
          }&strategyId=${strategyId}`
        );
        const newSymbolData = { ...symbolData };
        if (!newSymbolData[trade.symbol]) {
          newSymbolData[trade.symbol] = {};
        }
        const yearMonth = getYearMonth(month);
        if (!newSymbolData[trade.symbol][yearMonth]) {
          newSymbolData[trade.symbol][yearMonth] = [];
        }
        newSymbolData[trade.symbol][yearMonth] = [
          ...newSymbolData[trade.symbol][yearMonth],
          ...JSON.parse(result),
        ];
        symbolData = newSymbolData;
      }
    }
    const candlesAsArray = [];
    for (const month of requiredMonths) {
      const symbolDataForMonth =
        symbolData[trade.symbol]?.[getYearMonth(month)] || [];
      candlesAsArray.push(...symbolDataForMonth);
    }
    const shownData = {
      candles: candlesAsArray.filter(
        (c) => c.openTime >= firstShownHour && c.openTime <= lastShownHour
      ),
      trade,
    };
    setLoadingSymbolData(false);
    setShownSymbolData(shownData);
  };

  return (
    <Container>
      <TradeListTable
        tradeList={tradeList}
        showTradeDetails={showTradeDetails}
      ></TradeListTable>
      <TradeDetails
        shownSymbolData={shownSymbolData}
        closeDetails={() => setCurrentlyShownTrade(null)}
        showNextTrade={showNextTrade}
        showPreviousTrade={showPreviousTrade}
        loadingSymbolData={loadingSymbolData}
        currentlyShownTrade={currentlyShownTrade}
        opposingTradeHandling={opposingTradeHandling}
      ></TradeDetails>
    </Container>
  );
};

export default TradeList;
