import React, { useEffect, useState } from "react";
import CodeEditor from "./Components/CodeEditor";
import CodeEditorColumn from "./Components/CodeEditorColumn";
import CodeEditorHeader from "./Components/CodeEditorHeader";
import styled from "styled-components";
import BacktestControls from "./Components/BacktestControls";
import { Button, TextField } from "@material-ui/core";
import breakpoints from "../../../../Common/Constants/breakpoints";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #07202f8a;
  height: 100vh;
  width: 100%;
`;

const StrategyTitle = styled.h2`
  padding: 12px;
  margin-bottom: 0;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DecisionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CodeEditorColumnDecisions = styled(CodeEditorColumn)`
  width: 450px;
  margin: 12px;
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const CodeEditorColumnConfig = styled(CodeEditorColumn)`
  width: 1200px;
  margin: 12px;
  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const CodeEditorsOuterContainer = styled.div``;

const CodeEditorsInnerContainer = styled.div`
  display: flex;
  min-height: 70vh;
`;
const CodeMetaDataAndControls = styled.div`
  display: flex;
  margin: 6px 12px 18px 12px;
  justify-content: space-between;
`;
const NameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  > .MuiTextField-root {
    margin-bottom: 9px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > button {
    margin-top: 9px;
  }
`;

const StrategyEditing = ({
  startBacktest,
  saveStrategy,
  collectedSymbols,
  currentlyViewedStrategy,
  backtestsOfViewedStrategy,
  loadBacktestResults,
  loadingBacktestResults,
  viewBacktestResults,
}) => {
  const [strategyCode, setStrategyCode] = useState(
    currentlyViewedStrategy.code
  );
  const [strategyName, setStrategyName] = useState(
    currentlyViewedStrategy.strategy.name
  );
  const [strategyDescription, setStrategyDescription] = useState(
    currentlyViewedStrategy.strategy.description
  );

  const handleOpeningDecisionChange = (value) => {
    setStrategyCode({ ...strategyCode, openingDecision: value });
  };
  const handleClosingDecisionChange = (value) => {
    setStrategyCode({ ...strategyCode, closingDecision: value });
  };
  const handleConfigChange = (value) => {
    setStrategyCode({ ...strategyCode, config: value });
  };

  useEffect(() => {
    setStrategyName(currentlyViewedStrategy.strategy.name);
    setStrategyDescription(currentlyViewedStrategy.strategy.description);
    setStrategyCode(currentlyViewedStrategy.code);
  }, [currentlyViewedStrategy]);

  return (
    <OuterContainer>
      <StrategyTitle>
        {currentlyViewedStrategy.strategy.name
          ? currentlyViewedStrategy.strategy.name
          : "Create New Strategy"}
      </StrategyTitle>
      <InnerContainer>
        <CodeEditorsOuterContainer>
          <CodeEditorsInnerContainer>
            <DecisionsContainer>
              <CodeEditorColumnDecisions>
                <CodeEditorHeader>Opening Decision</CodeEditorHeader>
                <CodeEditor
                  wordWrapColumn={130}
                  defaultValue={strategyCode.openingDecision}
                  handleEditorChange={handleOpeningDecisionChange}
                  readOnly={currentlyViewedStrategy.strategy.id}
                  height="30vh"
                ></CodeEditor>
              </CodeEditorColumnDecisions>
              <CodeEditorColumnDecisions>
                <CodeEditorHeader>Closing Decision</CodeEditorHeader>
                <CodeEditor
                  wordWrapColumn={130}
                  defaultValue={strategyCode.closingDecision}
                  handleEditorChange={handleClosingDecisionChange}
                  readOnly={currentlyViewedStrategy.strategy.id}
                  height="30vh"
                ></CodeEditor>
              </CodeEditorColumnDecisions>
            </DecisionsContainer>
            <CodeEditorColumnConfig>
              <CodeEditorHeader>Configuration</CodeEditorHeader>
              <CodeEditor
                wordWrapColumn={160}
                defaultValue={strategyCode.config}
                handleEditorChange={handleConfigChange}
                readOnly={currentlyViewedStrategy.strategy.id}
                height="70vh"
              ></CodeEditor>
            </CodeEditorColumnConfig>
          </CodeEditorsInnerContainer>
          {!currentlyViewedStrategy.strategy.id && (
            <CodeMetaDataAndControls>
              <NameAndDescription>
                <TextField
                  color="secondary"
                  label="Strategy Name"
                  value={strategyName}
                  onChange={(event) => setStrategyName(event.target.value)}
                />
                <TextField
                  color="secondary"
                  label="Strategy Description"
                  rows={2}
                  multiline
                  value={strategyDescription}
                  onChange={(event) =>
                    setStrategyDescription(event.target.value)
                  }
                />
              </NameAndDescription>
              <ButtonContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    saveStrategy({
                      ...strategyCode,
                      name: strategyName,
                      description: strategyDescription,
                    })
                  }
                >
                  Save Strategy
                </Button>
              </ButtonContainer>
            </CodeMetaDataAndControls>
          )}
        </CodeEditorsOuterContainer>
        <BacktestControls
          strategyCode={strategyCode}
          startBacktest={startBacktest}
          collectedSymbols={collectedSymbols}
          backtestsOfViewedStrategy={backtestsOfViewedStrategy}
          loadBacktestResults={loadBacktestResults}
          loadingBacktestResults={loadingBacktestResults}
          viewBacktestResults={viewBacktestResults}
        ></BacktestControls>
      </InnerContainer>
    </OuterContainer>
  );
};

export default StrategyEditing;
