import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 3px;
  margin-bottom: 3px;
`;

const AnalysisListItemReason = ({ decisions }) => {
  return <Container>{JSON.stringify(decisions)}</Container>;
};

export default AnalysisListItemReason;
