import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import shortenFullIdentifier from "../../../../../../Common/Functions/shortenFullIdentifier";
import formatPercentage from "../../../../../../Common/Functions/formatPercentage";

const MonthlyTable = ({ monthList }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="sum-area"></TableCell>
            <TableCell className="sum-area" align="right">
              TOTAL_SUM
            </TableCell>
            <TableCell className="sum-area" align="right">
              TOTAL_MEA
            </TableCell>
            {monthList.allSymbols.map((symbol) => (
              <TableCell className="sum-area" align="right" key={symbol}>
                {shortenFullIdentifier(symbol)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="sum-area" scope="row" align="right">
              TOTAL_MEA
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              {formatPercentage(monthList.sum)}
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              {formatPercentage(monthList.mean)}
            </TableCell>
            {monthList.allSymbols.map((symbol) => {
              const dataForMonthAndSymbol = monthList.totalResultPerSymbol.find(
                (t) => t.symbol === symbol
              );
              return (
                <TableCell className="sum-area" align="right" key={symbol}>
                  {formatPercentage(dataForMonthAndSymbol.mean)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {monthList.months.map((month) => (
            <TableRow key={month.monthAndDate}>
              <TableCell scope="row" align="right" className="sum-area">
                {month.monthAndDate}
              </TableCell>
              <TableCell className="sum-area" scope="row" align="right">
                {formatPercentage(month.sum)}
              </TableCell>
              <TableCell className="sum-area" scope="row" align="right">
                {formatPercentage(month.mean)}
              </TableCell>
              {monthList.allSymbols.map((symbol) => {
                const dataForMonthAndSymbol = month.tradesPerSymbol.find(
                  (t) => t.symbol === symbol
                );
                return (
                  <TableCell align="right" key={symbol}>
                    {formatPercentage(dataForMonthAndSymbol.mean)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MonthlyTable;
