import React from "react";
import { IconButton, LinearProgress } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import AreaContainer from "../../../../ControllingModule/Components/AreaContainer";
import styled from "styled-components";
import OpenPositionItem from "./OpenPositionItem";
import NoDataHint from "../../../../ControllingModule/Components/NoDataHint";
import HeaderContainer from "../../../../ControllingModule/Components/HeaderContainer";

const Container = styled.div`
  min-width: 450px;
  width: 450px;
`;

const OpenPositionList = ({
  openPositions,
  loadOpenPositions,
  loadingOpenPositions,
}) => {
  return (
    <Container>
      <HeaderContainer>
        <h2>Currently Open Positions</h2>
        <IconButton onClick={() => loadOpenPositions()}>
          <Refresh fontSize="inherit"></Refresh>
        </IconButton>
      </HeaderContainer>
      <AreaContainer>
        {loadingOpenPositions ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : (
          <>
            {openPositions.length ? (
              openPositions.map((position, index) => (
                <OpenPositionItem
                  position={position}
                  key={index}
                ></OpenPositionItem>
              ))
            ) : (
              <NoDataHint>No Data</NoDataHint>
            )}
          </>
        )}
      </AreaContainer>
    </Container>
  );
};

export default OpenPositionList;
