import findHighestOrLowestValueInCandles from "../../../../../../Common/Functions/findHighestOrLowestValueInCandles";

const getPercentage = (value, direction, entryPrice) => {
  let result;
  if (direction.indexOf("OPEN_LONG") === 0) {
    result = (value - entryPrice) / entryPrice;
  } else if (direction.indexOf("OPEN_SHORT") === 0) {
    result = (entryPrice - value) / entryPrice;
  }
  return result;
};

const calculateChartData = (shownSymbolData) => {
  const candles = shownSymbolData.candles.map((candle) => {
    return [
      candle.openTime,
      candle.open,
      candle.high,
      candle.low,
      candle.close,
    ];
  });
  let candlesWithinTradePeriod = candles
    .filter(
      (c) =>
        c[0] >= shownSymbolData.trade.openTime &&
        c[0] <= shownSymbolData.trade.closeTime
    )
    .map((candle) => {
      return {
        openTime: candle[0],
        open: candle[1],
        high: candle[2],
        low: candle[3],
        close: candle[4],
      };
    });
  const entryPrice = shownSymbolData.trade.entryPrice;
  const closePrice = shownSymbolData.trade.closePrice;
  const upsidePotential = Math.max(
    findHighestOrLowestValueInCandles(
      candlesWithinTradePeriod,
      "close",
      "highest"
    ),
    entryPrice
  );
  const downsidePotential = Math.min(
    findHighestOrLowestValueInCandles(
      candlesWithinTradePeriod,
      "close",
      "lowest"
    ),
    entryPrice
  );
  const upsidePotentialIntrahour = Math.max(
    findHighestOrLowestValueInCandles(
      candlesWithinTradePeriod,
      "high",
      "highest"
    ),
    entryPrice
  );
  const downsidePotentialIntrahour = Math.min(
    findHighestOrLowestValueInCandles(
      candlesWithinTradePeriod,
      "low",
      "lowest"
    ),
    entryPrice
  );
  const realizedUpsidePotential = Math.max(
    entryPrice,
    shownSymbolData.trade.closePrice
  );
  const realizedDownsidePotential = Math.min(
    entryPrice,
    shownSymbolData.trade.closePrice
  );
  if (candlesWithinTradePeriod.length === 1) {
    candlesWithinTradePeriod = [
      candlesWithinTradePeriod[0],
      {
        ...candlesWithinTradePeriod[0],
        openTime: candlesWithinTradePeriod[0].openTime + 1000 * 60 * 40,
      },
    ];
  }
  const chartData = {
    candles: candles,
    positionDrawing: {
      closePrice: candlesWithinTradePeriod.map((c) => [c.openTime, closePrice]),
      entryPrice: candlesWithinTradePeriod.map((c) => [c.openTime, entryPrice]),
      upsidePotential: candlesWithinTradePeriod.map((c) => [
        c.openTime,
        entryPrice,
        upsidePotential,
      ]),
      downsidePotential: candlesWithinTradePeriod.map((c) => [
        c.openTime,
        entryPrice,
        downsidePotential,
      ]),
      upsidePotentialIntrahour: candlesWithinTradePeriod.map((c) => [
        c.openTime,
        upsidePotential,
        upsidePotentialIntrahour,
      ]),
      downsidePotentialIntrahour: candlesWithinTradePeriod.map((c) => [
        c.openTime,
        downsidePotential,
        downsidePotentialIntrahour,
      ]),
      realizedUpsidePotential: candlesWithinTradePeriod.map((c) => [
        c.openTime,
        entryPrice,
        realizedUpsidePotential,
      ]),
      realizedDownsidePotential: candlesWithinTradePeriod.map((c) => [
        c.openTime,
        entryPrice,
        realizedDownsidePotential,
      ]),
    },
    positionDrawingData: {
      entryPrice: entryPrice,
      upsidePotential: upsidePotential,
      downsidePotential: downsidePotential,
      upsidePotentialIntrahour: upsidePotentialIntrahour,
      downsidePotentialIntrahour: downsidePotentialIntrahour,
      realizedUpsidePotential: realizedUpsidePotential,
      realizedDownsidePotential: realizedDownsidePotential,

      upsidePotentialPercentage: getPercentage(
        upsidePotential,
        shownSymbolData.trade.type,
        entryPrice
      ),
      downsidePotentialPercentage: getPercentage(
        downsidePotential,
        shownSymbolData.trade.type,
        entryPrice
      ),
      upsidePotentialIntrahourPercentage: getPercentage(
        upsidePotentialIntrahour,
        shownSymbolData.trade.type,
        entryPrice
      ),
      downsidePotentialIntrahourPercentage: getPercentage(
        downsidePotentialIntrahour,
        shownSymbolData.trade.type,
        entryPrice
      ),
      realizedUpsidePotentialPercentage: getPercentage(
        realizedUpsidePotential,
        shownSymbolData.trade.type,
        entryPrice
      ),
      realizedDownsidePotentialPercentage: getPercentage(
        realizedDownsidePotential,
        shownSymbolData.trade.type,
        entryPrice
      ),
    },
  };
  return chartData;
};

export default calculateChartData;
