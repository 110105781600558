const addPositionDrawingToChart = (shownSymbolData, options, chartData) => {
  const baseUpsideColor =
    shownSymbolData.trade.type.indexOf("OPEN_LONG") === 0
      ? "#9bffa3"
      : "#eb7070";
  const baseDownsideColor =
    shownSymbolData.trade.type.indexOf("OPEN_SHORT") === 0
      ? "#9bffa3"
      : "#eb7070";
  options.series = [
    ...options.series,
    {
      name: "realizedDownsidePotential",
      type: "arearange",
      data: chartData.positionDrawing.realizedDownsidePotential,
      fillColor: `${baseDownsideColor}aa`,
      marker: { enabled: false },
      lineColor: baseDownsideColor,
      enableMouseTracking: false,
    },
    {
      name: "realizedUpsidePotential",
      type: "arearange",
      data: chartData.positionDrawing.realizedUpsidePotential,
      fillColor: `${baseUpsideColor}aa`,
      marker: { enabled: false },
      lineColor: baseUpsideColor,
      enableMouseTracking: false,
    },
    {
      name: "downsidePotential",
      type: "arearange",
      data: chartData.positionDrawing.downsidePotential,
      fillColor: `${baseDownsideColor}66`,
      marker: { enabled: false },
      lineColor: baseDownsideColor,
      enableMouseTracking: false,
    },
    {
      name: "upsidePotential",
      type: "arearange",
      data: chartData.positionDrawing.upsidePotential,
      fillColor: `${baseUpsideColor}66`,
      marker: { enabled: false },
      lineColor: baseUpsideColor,
      enableMouseTracking: false,
    },
    {
      name: "downsidePotentialIntrahour",
      type: "arearange",
      data: chartData.positionDrawing.downsidePotentialIntrahour,
      fillColor: `${baseDownsideColor}33`,
      marker: { enabled: false },
      lineColor: baseDownsideColor,
      enableMouseTracking: false,
    },
    {
      name: "upsidePotentialIntrahour",
      type: "arearange",
      data: chartData.positionDrawing.upsidePotentialIntrahour,
      fillColor: `${baseUpsideColor}33`,
      marker: { enabled: false },
      lineColor: baseUpsideColor,
      enableMouseTracking: false,
    },
    {
      name: "entryPrice",
      type: "line",
      data: chartData.positionDrawing.entryPrice,
      lineColor: "#eee",
      marker: { enabled: false },
      enableMouseTracking: false,
    },
    {
      name: "closePrice",
      type: "line",
      data: chartData.positionDrawing.closePrice,
      lineColor: "#eee",
      marker: { enabled: false },
      enableMouseTracking: false,
    },
  ];
};

export default addPositionDrawingToChart;
