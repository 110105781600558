import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const TenKChart = ({ tenKList, historicalData }) => {
  const [chartData, setChartData] = useState([]);
  const [transformedHistoricalData, setTransformedHistoricalData] = useState(
    []
  );

  useEffect(() => {
    if (historicalData.length && chartData.length) {
      const transformedHistorical = [];
      const result = historicalData
        .filter(
          (day) =>
            day.day > chartData[0][0] &&
            day.day < chartData[chartData.length - 1][0]
        )
        .map((day) => [day.day, day.closePrice]);
      transformedHistorical.push({
        type: "line",
        data: result,
        name: "BTC",
        color: "#948d10",
      });
      setTransformedHistoricalData(transformedHistorical);
    }
  }, [historicalData, chartData]);

  useEffect(() => {
    const data = tenKList.days.map((day) => {
      return [day.time, day.balanceAllTime];
    });
    setChartData(data);
  }, [tenKList]);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
      backgroundColor: "#05161f",
      style: { fontFamily: "Fira Code" },
    },
    xAxis: {
      type: "datetime",
      lineColor: "#333333",
      tickInterval: 24 * 3600 * 1000 * 30 * 3, // one quarter
      tickLength: 400,
      tickPosition: "inside",
      tickColor: "#333333",
    },
    yAxis: {
      type: "logarithmic",
      gridLineColor: "#333333",
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        type: "area",
        data: chartData,
        name: "invest",
      },
      ...transformedHistoricalData,
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default TenKChart;
