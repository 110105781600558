import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Symbol = styled.h3``;
const SymbolLong = styled.div``;
const Time = styled.div``;

const AnalysisListItemSymbol = ({ decisions, type, symbol, time }) => {
  const formattedTime = new Date(time).toUTCString();
  const symbolShort = symbol.split("-")[1].replace("USDTM", "");

  return (
    <Container>
      <Time>{type}</Time>
      <Symbol>{symbolShort}</Symbol>
      <SymbolLong>{symbol}</SymbolLong>
      <Time>{formattedTime}</Time>
    </Container>
  );
};

export default AnalysisListItemSymbol;
