import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  Chip,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import ErrorDialog from "../../../../../Common/Components/Dialogs/ErrorDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
  }
`;

const SelectStrategyDialog = ({ visible, setVisible, initialConfig }) => {
  const [selectedStrategy, setSelectedStrategy] = useState(
    initialConfig.strategy || ""
  );
  const [selectedSymbols, setSelectedSymbols] = useState(
    initialConfig.symbols || []
  );
  const [leverage, setLeverage] = useState(initialConfig.leverage || 1);
  const [investmentPercentage, setInvestmentPercentage] = useState(
    initialConfig.investmentPercentage || 0.2
  );
  const [concurrentTrades, setConcurrentTrades] = useState(
    initialConfig.concurrentTrades || false
  );
  const [opposingTradeHandling, setOpposingTradeHandling] = useState(
    initialConfig.opposingTradeHandling || "CLOSE_AND_OPEN"
  );
  const [hardStopLoss, setHardStopLoss] = useState(
    initialConfig.hardStopLoss || -0.02
  );
  const [hardStopDuration, setHardStopDuration] = useState(
    initialConfig.hardStopDuration || 100
  );

  const [collectedSymbols, setCollectedSymbols] = useState([]);
  const [existingStrategies, setExistingStrategies] = useState([]);
  const [loadingStrategies, setLoadingStrategies] = useState(true);
  const [loadingCollectedSymbols, setLoadingCollectedSymbols] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showSaveError, setShowSaveError] = useState(false);
  const [textSaveError, setTextSaveError] = useState(null);

  const saveSelectedStrategy = async () => {
    setLoadingSave(true);
    setTextSaveError(null);
    try {
      await axios.post(`trading-manager-kucoin/active-strategy`, {
        strategy: selectedStrategy,
        selectedSymbols: selectedSymbols,
        leverage: leverage,
        investmentPercentage: investmentPercentage,
        concurrentTrades: concurrentTrades,
        opposingTradeHandling: opposingTradeHandling,
        hardStopLoss: hardStopLoss,
        hardStopDuration: hardStopDuration,
      });
      setLoadingSave(false);
      setVisible(false);
    } catch (error) {
      setShowSaveError(true);
      setTextSaveError(JSON.stringify(error));
      setLoadingSave(false);
    }
  };

  const loadCollectedSymbols = async () => {
    setLoadingCollectedSymbols(true);
    const { data: symbols } = await axios.get(
      `strategy-manager/collected-symbols`
    );
    setCollectedSymbols(symbols);
    setLoadingCollectedSymbols(false);
  };

  const loadExistingStrategies = async () => {
    setLoadingStrategies(true);
    const { data: strategies } = await axios.get(
      `strategy-manager/strategy-list`
    );
    setExistingStrategies(strategies);
    setLoadingStrategies(false);
  };

  useEffect(() => {
    loadCollectedSymbols();
    loadExistingStrategies();
  }, []);

  return (
    <StyledDialog open={visible}>
      <DialogTitle>Select Strategy</DialogTitle>
      <DialogContent>
        {(loadingStrategies || loadingCollectedSymbols) && (
          <>loadingStrategies</>
        )}
        {!loadingStrategies && !existingStrategies.length && (
          <>No existing strategies. Please create a strategy first.</>
        )}
        {!loadingStrategies &&
          !loadingCollectedSymbols &&
          existingStrategies.length && (
            <>
              <Autocomplete
                multiple
                options={collectedSymbols.map(
                  (symbol) =>
                    `${symbol.exchange}_${symbol.type}-${symbol.symbol}`
                )}
                value={selectedSymbols}
                onChange={(event, newValue) => {
                  setSelectedSymbols(newValue);
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} color="secondary" label="Symbols" />
                )}
              />
              <FormControl>
                <InputLabel id="selected-strategy-label">
                  Selected Strategy
                </InputLabel>
                <Select
                  fullWidth
                  color="secondary"
                  labelId="selected-strategy-label"
                  value={selectedStrategy}
                  onChange={(e) => setSelectedStrategy(e.target.value)}
                >
                  {existingStrategies.map((strategy) => (
                    <MenuItem key={strategy.id} value={strategy.id}>
                      {strategy.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="investmentPercentage-label">
                  Investment Percentage
                </InputLabel>
                <Select
                  fullWidth
                  color="secondary"
                  labelId="investmentPercentage-label"
                  value={investmentPercentage}
                  onChange={(e) => setInvestmentPercentage(e.target.value)}
                >
                  <MenuItem value={0.04}>0.04</MenuItem>
                  <MenuItem value={0.05}>0.05</MenuItem>
                  <MenuItem value={0.06}>0.06</MenuItem>
                  <MenuItem value={0.07}>0.07</MenuItem>
                  <MenuItem value={0.08}>0.08</MenuItem>
                  <MenuItem value={0.1}>0.10</MenuItem>
                  <MenuItem value={0.11}>0.11</MenuItem>
                  <MenuItem value={0.12}>0.12</MenuItem>
                  <MenuItem value={0.14}>0.14</MenuItem>
                  <MenuItem value={0.16}>0.16</MenuItem>
                  <MenuItem value={0.2}>0.20</MenuItem>
                  <MenuItem value={0.25}>0.25</MenuItem>
                  <MenuItem value={0.33}>0.33</MenuItem>
                  <MenuItem value={0.5}>0.50</MenuItem>
                  <MenuItem value={1.0}>1.00</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="leverage-label">Leverage</InputLabel>
                <Select
                  fullWidth
                  color="secondary"
                  labelId="leverage-label"
                  value={leverage}
                  onChange={(e) => setLeverage(e.target.value)}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={14}>14</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={concurrentTrades}
                    onChange={(e) => setConcurrentTrades(e.target.checked)}
                    name="concurrentTrades"
                  />
                }
                label="Concurrent Trades"
              />
              <FormControl>
                <InputLabel id="opposingTradeHandling-label">
                  Opposing Trade Handling
                </InputLabel>
                <Select
                  fullWidth
                  color="secondary"
                  labelId="opposingTradeHandling-label"
                  value={opposingTradeHandling}
                  onChange={(e) => setOpposingTradeHandling(e.target.value)}
                >
                  <MenuItem value={"KEEP_AND_OPEN"}>
                    Keep existing & open new position
                  </MenuItem>
                  <MenuItem value={"CLOSE_AND_OPEN"}>
                    Close existing & do open new position
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                color="secondary"
                label="Hard Stop Loss"
                fullWidth
                value={hardStopLoss}
                onChange={(event) =>
                  setHardStopLoss(Number.parseFloat(event.target.value))
                }
              />
              <TextField
                color="secondary"
                label="Hard Stop Duration (h)"
                fullWidth
                value={hardStopDuration}
                onChange={(event) =>
                  setHardStopDuration(Number.parseFloat(event.target.value))
                }
              />
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={loadingStrategies || loadingSave}
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingStrategies || loadingSave}
          onClick={saveSelectedStrategy}
        >
          Save
        </Button>
      </DialogActions>
      <ErrorDialog
        errorText={textSaveError}
        open={showSaveError}
        setOpen={setShowSaveError}
        title="Error"
      ></ErrorDialog>
    </StyledDialog>
  );
};

export default SelectStrategyDialog;
