const findBiggestDifference = (obj, close) => {
  return Math.max(
    ...Object.values(obj).map((value) => Math.abs(value - close))
  );
};

const THRESHOLD_FOR_OWN_SCALE = 0.3;

const addIndicatorsToChart = (
  shownIndicators,
  allIndicators,
  shownSymbolData,
  options
) => {
  allIndicators.forEach((indicator) => {
    if (shownIndicators.includes(indicator)) {
      let ownScale;
      let newSeries = [];
      const indicatorValues = Object.keys(
        shownSymbolData.candles[0].ti[indicator]
      );
      if (indicatorValues.length > 0) {
        const difference = findBiggestDifference(
          shownSymbolData.candles[0].ti[indicator],
          shownSymbolData.candles[0].close
        );
        ownScale =
          difference / shownSymbolData.candles[0].close >
          THRESHOLD_FOR_OWN_SCALE;
        indicatorValues.forEach((indicatorValue) => {
          newSeries.push({
            name: `${indicator}-${indicatorValue}`,
            type: "line",
            data: shownSymbolData.candles.map((c) => [
              c.openTime,
              c.ti[indicator][indicatorValue],
            ]),
            marker: { enabled: false },
          });
        });
      } else {
        const difference = Math.abs(
          shownSymbolData.candles[0].ti[indicator] -
            shownSymbolData.candles[0].close
        );
        ownScale =
          difference / shownSymbolData.candles[0].close >
          THRESHOLD_FOR_OWN_SCALE;
        newSeries.push({
          name: `${indicator}`,
          type: "line",
          data: shownSymbolData.candles.map((c) => [
            c.openTime,
            c.ti[indicator],
          ]),
          marker: { enabled: false },
        });
      }
      if (ownScale) {
        options.yAxis = [
          ...options.yAxis,
          {
            gridLineWidth: 0,
            title: {
              text: `${indicator}`,
            },
          },
        ];
        newSeries = newSeries.map((s) => {
          return { ...s, yAxis: options.yAxis.length - 1 };
        });
        options.series = [...options.series, ...newSeries];
      } else {
        options.series = [...options.series, ...newSeries];
      }
    }
  });
};

export default addIndicatorsToChart;
