import { LinearProgress } from "@material-ui/core";
import ShortUniqueId from "short-unique-id";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FilterPanel from "./FilterPanel/FilterPanel";
import ResultTable from "./ResultTable/ResultTable";

const uid = new ShortUniqueId({ length: 8 });
const Container = styled.div`
  display: flex;
`;

const ResultTablePlaceHolder = styled.div`
  flex: 7;
`;

let getDefinitions;
// let getIdFromConfig;
let getConfigFromId;
let definitions;

const WelfiResultsModule = () => {
  const [loadingResults, setLoadingResults] = useState(false);
  const [loadingDefinitionFiles, setLoadingDefinitionFiles] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const [results, setResults] = useState([]);

  const loadDefinitionFiles = async () => {
    setLoadingDefinitionFiles(true);
    const { data: definitionFiles } = await axios.get(
      `welfi-result-manager/welfi-config-data`
    );
    // eslint-disable-next-line no-new-func
    getDefinitions = new Function(definitionFiles.definitions);
    // eslint-disable-next-line no-new-func
    // getIdFromConfig = new Function(definitionFiles.getIdFromConfig);

    // eslint-disable-next-line no-new-func, no-eval
    getConfigFromId = eval(definitionFiles.getConfigFromId);
    definitions = getDefinitions();
    setLoadingDefinitionFiles(false);
    loadWelfiResults({
      filters: [],
      sorts: [],
      limit: 1000,
      count: true,
    });
  };

  const loadWelfiResults = async (queryParameters) => {
    setLoadingResults(true);
    const { data: results } = await axios.post(
      // For direct call via Function URL (because call takes too long and falls in API Gateway timeout)
      `https://owwosr5pap3hqwseut3hqlyizi0wrnmv.lambda-url.eu-west-1.on.aws/welfi-results/`,
      queryParameters
    );
    const transformedResults = results.items.map((item, index) => {
      return {
        ...item,
        config: [
          ...getConfigFromId(item.ID.slice(0, -2), definitions).filter(
            (c) => c.type !== "id"
          ),
          { type: "id", value: uid() },
          {
            type: "direction",
            value:
              item.ID.substring(item.ID.length - 1) === "L" ? "LONG" : "SHORT",
          },
        ],
      };
    });
    setResults(transformedResults);
    setTotalCount(results.totalCount);
    setLoadingResults(false);
  };

  useEffect(() => {
    loadDefinitionFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2>Welfi Results</h2>
      <Container>
        <FilterPanel loadWelfiResults={loadWelfiResults}></FilterPanel>
        {!loadingResults && !loadingDefinitionFiles ? (
          <ResultTable
            resultList={results}
            totalCount={totalCount}
          ></ResultTable>
        ) : (
          <ResultTablePlaceHolder>
            <LinearProgress color="secondary"></LinearProgress>
            <div>Loading Results</div>
          </ResultTablePlaceHolder>
        )}
      </Container>
    </div>
  );
};

export default WelfiResultsModule;
