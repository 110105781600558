import axios from "axios";
import React, { useEffect, useState } from "react";
import OpenPositionList from "./Components/OpenPositionList";

const OpenPositions = () => {
  const [loadingOpenPositions, setLoadingOpenPositions] = useState(true);
  const [openPositions, setOpenPositions] = useState([]);

  const loadOpenPositions = async () => {
    setLoadingOpenPositions(true);
    const { data: tradeList } = await axios.get(
      `trading-manager-kucoin/open-position-list`
    );
    setLoadingOpenPositions(false);
    setOpenPositions(tradeList);
  };

  useEffect(() => {
    loadOpenPositions();
  }, []);

  return (
    <OpenPositionList
      openPositions={openPositions}
      loadOpenPositions={loadOpenPositions}
      loadingOpenPositions={loadingOpenPositions}
    ></OpenPositionList>
  );
};

export default OpenPositions;
