import { IconButton } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import shortenFullIdentifier from "../../../../../Common/Functions/shortenFullIdentifier";

const Container = styled.div`
  background-color: #345061;
  padding: 6px;
  border-radius: 4px;
  margin: 6px 0;
`;
const BacktestDate = styled.h3`
  margin: 0;
  font-size: 14px;
`;
const BacktestRange = styled.p`
  margin: 0;
  font-size: 12px;
`;
const BacktestSymbols = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 12px;
`;
const ButtonContainer = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
`;

const BacktestListItem = ({ backtest, viewBacktestResults }) => {
  return (
    <Container
      onClick={() => {
        if (backtest.completedSymbols.length === backtest.startedSymbols.length)
          viewBacktestResults(backtest.id);
      }}
    >
      <BacktestDate>
        {`${new Date(backtest.creationDate).toLocaleDateString()} - ${new Date(
          backtest.creationDate
        ).toLocaleTimeString()}`}
      </BacktestDate>
      <BacktestRange>
        {`${new Date(backtest.startTime).toLocaleDateString()} - ${new Date(
          backtest.endTime
        ).toLocaleDateString()}`}
      </BacktestRange>
      <BacktestSymbols>
        {backtest.completedSymbols.length}/{backtest.startedSymbols.length} (
        {backtest.startedSymbols.map((s) => shortenFullIdentifier(s)).join("|")}
        )
      </BacktestSymbols>
      <ButtonContainer>
        <IconButton
          size="small"
          disabled={
            backtest.completedSymbols.length < backtest.startedSymbols.length
          }
        >
          <Launch fontSize="inherit"></Launch>
        </IconButton>
      </ButtonContainer>
    </Container>
  );
};

export default BacktestListItem;
