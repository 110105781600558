import React from "react";
import ResultAreaContainer from "../Components/ResultAreaContainer";
import MonthlyTable from "./Components/MonthlyTable";

const MonthlyPerformance = ({ monthList }) => {
  return (
    <ResultAreaContainer>
      <MonthlyTable monthList={monthList}></MonthlyTable>
    </ResultAreaContainer>
  );
};

export default MonthlyPerformance;
