import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Highcharts from "highcharts";
import highchartsStock from "highcharts/modules/stock";
import indicators from "highcharts/indicators/indicators";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import addPositionDrawingToChart from "../Functions/addPositionDrawingToChart";
import getBaseOptionsOfChart from "../Functions/getBaseOptionsOfChart";
import calculateChartData from "../Functions/calculateChartData";
import { Close } from "@material-ui/icons";
import addIndicatorsToChart from "../Functions/addIndicatorsToChart";
import IndicatorSelection from "./IndicatorSelection";
import TradeBaseData from "./TradeDetailInfos/TradeBaseData";
import TradeStrategy from "./TradeDetailInfos/TradeStrategy";
import TradeCommercialResults from "./TradeDetailInfos/TradeCommercialResults";
import ResultAreaContainer from "../../Components/ResultAreaContainer";
import TradePositionChart from "./TradeDetailInfos/TradePositionChart";

highchartsStock(Highcharts);
indicators(Highcharts);
highchartsMore(Highcharts);

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .MuiPaper-root {
    min-height: 80vh;
    background-color: #1b3342;
  }
`;

const Container = styled.div`
  display: flex;
`;
const MainColumn = styled.div`
  flex: 3;
`;
const SideColumn = styled(ResultAreaContainer)`
  flex: 1;
  min-width: 300px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  > div {
    margin-bottom: 12px;
  }
`;

let allIndicators = [];

const TradeDetails = ({
  shownSymbolData,
  closeDetails,
  showNextTrade,
  showPreviousTrade,
  currentlyShownTrade,
  loadingSymbolData,
  opposingTradeHandling,
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [shownIndicators, setShownIndicators] = useState([]);
  const [positionDrawingData, setPositionDrawingData] = useState({});

  useEffect(() => {
    if (shownSymbolData?.candles?.length) {
      allIndicators = Object.keys(shownSymbolData.candles[0].ti);
      const chartData = calculateChartData(shownSymbolData);
      setPositionDrawingData(chartData.positionDrawingData);
      const options = getBaseOptionsOfChart(shownSymbolData, chartData);
      addPositionDrawingToChart(shownSymbolData, options, chartData);
      addIndicatorsToChart(
        shownIndicators,
        allIndicators,
        shownSymbolData,
        options
      );
      setChartOptions(options);
    }
  }, [shownSymbolData, currentlyShownTrade, shownIndicators]);

  return (
    <StyledDialog
      fullWidth
      maxWidth="xl"
      open={currentlyShownTrade !== null}
      onClose={closeDetails}
      disableBackdropClick
    >
      <DialogTitle>
        <span>Trade Details: Trade #{currentlyShownTrade + 1}</span>
        <IconButton onClick={closeDetails}>
          <Close></Close>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loadingSymbolData || !shownSymbolData?.candles?.length ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : (
          <Container>
            <MainColumn>
              <HighchartsReact
                containerProps={{ style: { height: "50vh", width: "100%" } }}
                highcharts={Highcharts}
                options={chartOptions}
              />
              <IndicatorSelection
                allIndicators={allIndicators}
                setShownIndicators={setShownIndicators}
                shownIndicators={shownIndicators}
              ></IndicatorSelection>
            </MainColumn>
            <SideColumn>
              <TradeBaseData trade={shownSymbolData.trade}></TradeBaseData>
              <TradeCommercialResults
                trade={shownSymbolData.trade}
              ></TradeCommercialResults>
              <TradeStrategy
                trade={shownSymbolData.trade}
                opposingTradeHandling={opposingTradeHandling}
              ></TradeStrategy>
              <TradePositionChart
                trade={shownSymbolData.trade}
                positionDrawingData={positionDrawingData}
              ></TradePositionChart>
            </SideColumn>
          </Container>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => showPreviousTrade()}>
          Previous
        </Button>
        <Button variant="contained" onClick={() => showNextTrade()}>
          Next
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default TradeDetails;
