import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styled from "styled-components";
import NoDataHint from "./NoDataHint";
import shortenFullIdentifier from "../../../../Common/Functions/shortenFullIdentifier";
import formatPositionType from "../../../../Common/Functions/formatPositionType";
import formatAmount from "../../../../Common/Functions/formatAmount";
import formatPercentage from "../../../../Common/Functions/formatPercentage";
import { Warning } from "@material-ui/icons";

const Container = styled.div`
  .MuiTableContainer-root {
    max-height: 90vh;
    overflow-y: auto;
    .MuiTableRow-root {
      vertical-align: baseline;
    }
    .MuiTableCell-stickyHeader {
      background-color: #072130;
    }
  }
`;

const DividedTableCell = styled(TableCell)`
  > div:first-child {
    font-weight: bold;
  }
  > div:last-child {
    opacity: 0.5;
  }
`;

const formatTradeLine = (value) => value || "-";

const formatDate = (date) => {
  if (!date) return "";
  const day = new Date(date).toISOString().split("T")[0].substring(5);
  const time = new Date(date).toISOString().split("T")[1].substring(0, 5);
  return `${day} | ${time}`;
};

const ControllingTable = ({ tradeList }) => {
  return (
    <Container>
      {tradeList.length ? (
        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">INFO</TableCell>
                <TableCell>SYM</TableCell>
                <TableCell>DIR</TableCell>
                <TableCell>STR</TableCell>
                <TableCell>STA</TableCell>
                <TableCell align="right">OP_T</TableCell>
                <TableCell align="right">CL_T</TableCell>
                <TableCell align="right">DUR</TableCell>
                <TableCell align="right">OP_P</TableCell>
                <TableCell align="right">CL_P</TableCell>
                <TableCell align="right">SIZ</TableCell>
                <TableCell align="right">MAR</TableCell>
                <TableCell align="right">GP</TableCell>
                <TableCell align="right">GP %</TableCell>
                <TableCell align="right">NP</TableCell>
                <TableCell align="right">NP %</TableCell>
                <TableCell align="right">CONC</TableCell>
                <TableCell align="right">OPNG</TableCell>
                <TableCell align="right">OPED</TableCell>
                <TableCell align="right">T</TableCell>
                <TableCell align="right">S</TableCell>
                <TableCell align="right">C</TableCell>
                <TableCell align="right">CREA</TableCell>
                <TableCell align="right">ACT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tradeList.map((trade) => (
                <TableRow key={trade.decisionId}>
                  <TableCell align="center">
                    <Warning></Warning>
                  </TableCell>
                  <TableCell scope="row">
                    {shortenFullIdentifier(trade.symbol)}
                  </TableCell>
                  <TableCell scope="row">
                    {formatPositionType(trade.direction)}
                  </TableCell>
                  <TableCell scope="row">{trade.strategy}</TableCell>
                  <TableCell scope="row">{trade.status}</TableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatTradeLine(
                        formatDate(trade.tradeFromDynamo?.openTime)
                      )}
                    </div>
                    <div>
                      {formatTradeLine(
                        formatDate(trade.tradeFromBacktest?.openTime)
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatTradeLine(
                        formatDate(trade.tradeFromDynamo?.closeTime)
                      )}
                    </div>
                    <div>
                      {formatTradeLine(
                        formatDate(trade.tradeFromBacktest?.closeTime)
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatTradeLine(trade.tradeFromDynamo?.durationHours)}
                    </div>
                    <div>
                      {formatTradeLine(trade.tradeFromBacktest?.durationHours)}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatTradeLine(
                        formatAmount(trade.tradeFromDynamo?.openPrice)
                      )}
                    </div>
                    <div>
                      {formatTradeLine(
                        formatAmount(trade.tradeFromBacktest?.entryPrice)
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatTradeLine(
                        formatAmount(trade.tradeFromDynamo?.closePrice)
                      )}
                    </div>
                    <div>
                      {formatTradeLine(
                        formatAmount(trade.tradeFromBacktest?.closePrice)
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatAmount(
                        trade.tradeFromDynamo?.sizeCryptoRounded,
                        0
                      )}
                    </div>
                    <div>
                      {formatAmount(
                        trade.tradeFromBacktest?.marginAllTime /
                          trade.tradeFromBacktest?.entryPrice /
                          trade.multiplier,
                        0
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatAmount(trade.tradeFromDynamo?.sizeUSDT, 2)}
                    </div>
                    <div>
                      {formatAmount(
                        trade.tradeFromBacktest?.marginAllTime / 4,
                        2
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatAmount(
                        trade.tradeFromDynamo?.grossProfitAbsolute,
                        2
                      )}
                    </div>
                    <div>
                      {formatAmount(
                        trade.tradeFromBacktest?.grossProfit *
                          trade.tradeFromBacktest?.marginAllTime,
                        2
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatPercentage(trade.tradeFromDynamo?.grossProfit)}
                    </div>
                    <div>
                      {formatPercentage(trade.tradeFromBacktest?.grossProfit)}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatAmount(
                        trade.tradeFromDynamo?.netProfitAbsolute,
                        2
                      )}
                    </div>
                    <div>
                      {formatAmount(
                        trade.tradeFromBacktest?.netProfit *
                          trade.tradeFromBacktest?.marginAllTime,
                        2
                      )}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {formatPercentage(trade.tradeFromDynamo?.netProfit)}
                    </div>
                    <div>
                      {formatPercentage(trade.tradeFromBacktest?.netProfit)}
                    </div>
                  </DividedTableCell>
                  <DividedTableCell scope="row" align="right">
                    <div>
                      {trade.tradeFromDynamo?.type.indexOf("_CONCURRENT") >= 0
                        ? "true"
                        : "false"}
                    </div>
                    <div>
                      {trade.tradeFromBacktest?.type.indexOf("_CONCURRENT") >= 0
                        ? "true"
                        : "false"}
                    </div>
                  </DividedTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataHint>No Data</NoDataHint>
      )}
    </Container>
  );
};

export default ControllingTable;
