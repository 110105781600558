import { ListItem, ListItemIcon } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BacktestIcon } from "../../../_assets/menu/backtest.svg";

const StyledListItemIcon = styled(ListItemIcon)`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: center;
  width: 100%;
  color: #b9e342;
  text-align: center;
`;
const StyledButton = styled(NavLink)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledIcon = styled.div`
  display: block;
  height: 40px;
  width: 40px;
  margin: 12px 12px 12px 6px;
`;

const Navigation = () => {
  return (
    <>
      <ListItem button>
        <StyledListItemIcon>
          <StyledButton
            exact
            activeClassName="active"
            to={{ pathname: `/trading/controlling/` }}
          >
            <StyledIcon>
              <BacktestIcon />
            </StyledIcon>
          </StyledButton>
          Con-trolling
        </StyledListItemIcon>
      </ListItem>
      <ListItem button>
        <StyledButton
          exact
          activeClassName="active"
          to={{ pathname: `/trading/active/` }}
        >
          <StyledListItemIcon>
            <StyledIcon>
              <BacktestIcon />
            </StyledIcon>
            Active
          </StyledListItemIcon>
        </StyledButton>
      </ListItem>
      <ListItem button>
        <StyledListItemIcon>
          <StyledButton
            exact
            activeClassName="active"
            to={{ pathname: `/trading/backtests/` }}
          >
            <StyledIcon>
              <BacktestIcon />
            </StyledIcon>
          </StyledButton>
          Backtests
        </StyledListItemIcon>
      </ListItem>
      <ListItem button>
        <StyledListItemIcon>
          <StyledButton
            exact
            activeClassName="active"
            to={{ pathname: `/trading/welfi-prios/` }}
          >
            <StyledIcon>
              <BacktestIcon />
            </StyledIcon>
          </StyledButton>
          Welfi-Prios
        </StyledListItemIcon>
      </ListItem>
      <ListItem button>
        <StyledListItemIcon>
          <StyledButton
            exact
            activeClassName="active"
            to={{ pathname: `/trading/welfi-results/` }}
          >
            <StyledIcon>
              <BacktestIcon />
            </StyledIcon>
          </StyledButton>
          Welfi-Results
        </StyledListItemIcon>
      </ListItem>
      <ListItem button>
        <StyledListItemIcon>
          <StyledButton
            exact
            activeClassName="active"
            to={{ pathname: `/trading/welfi-combinations/` }}
          >
            <StyledIcon>
              <BacktestIcon />
            </StyledIcon>
          </StyledButton>
          Welfi-Combis
        </StyledListItemIcon>
      </ListItem>
    </>
  );
};

export default Navigation;
