import TradingDashboardPage from "./AppPages/TradingDashboardPage/TradingDashboardPage";
import {
  unstable_createMuiStrictModeTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import axios from "axios";
import { useState } from "react";
import PreReleaseCurtain from "./AppPages/PreReleaseCurtain/PreReleaseCurtain";

const configureAxiosDefaults = () => {
  let baseURL =
    window.location.origin === process.env.REACT_APP_PROD_URL
      ? `${process.env.REACT_APP_PROD_URL}/api/`
      : process.env.REACT_APP_API_URL;
  // Use test backend instead of local backend if specific cookie is set and location is localhost
  const useTestBackend = document.cookie.replace(
    /(?:(?:^|.*;\s*)use-test-backend\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  if (
    window.location.hostname === "localhost" ||
    window.location.origin !== process.env.REACT_APP_PROD_URL
  ) {
    baseURL = process.env.REACT_APP_API_TEST_URL;
    axios.defaults.headers.common["X-Investiguana-Test-Request"] =
      process.env.REACT_APP_INVESTIGUANA_TEST_REQUEST_TOKEN;
    if (
      window.location.hostname === "localhost" &&
      useTestBackend === "false"
    ) {
      baseURL = process.env.REACT_APP_API_URL;
    }
  }

  axios.defaults.timeout = 120000;
  axios.defaults.baseURL = baseURL;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  configureAxiosDefaults();
  const theme = unstable_createMuiStrictModeTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#072130",
      },
      secondary: {
        main: "#b9e342",
      },
    },
    typography: { fontFamily: "Fira Code" },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loggedIn ? (
        <TradingDashboardPage></TradingDashboardPage>
      ) : (
        <PreReleaseCurtain setLoggedIn={setLoggedIn}></PreReleaseCurtain>
      )}
    </ThemeProvider>
  );
}

export default App;
