import React from "react";
import styled from "styled-components";
import formatPercentage from "../../../../../Common/Functions/formatPercentage";
import ResultAreaContainer from "../Components/ResultAreaContainer";
import Column from "../Components/SummaryComponents/Column";
import ColumnBody from "../Components/SummaryComponents/ColumnBody";
import ColumnBodyLine from "../Components/SummaryComponents/ColumnBodyLine";
import ColumnBodyLineGroup from "../Components/SummaryComponents/ColumnBodyLineGroup";
import ColumnHeader from "../Components/SummaryComponents/ColumnHeader";
import Label from "../Components/SummaryComponents/Label";
import LabelValuePair from "../Components/SummaryComponents/LabelValuePair";
import Value from "../Components/SummaryComponents/Value";
import { Scrollbars } from "react-custom-scrollbars";

const Container = styled(ResultAreaContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
`;

const VerticalTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 109px;
`;
const VerticalTable = styled.div`
  display: flex;
  flex-direction: column;
`;
const VerticalTableElement = styled.div`
  display: flex;
  flex-direction: row;
`;

const SimultanityAnalysis = ({ simultanityData }) => {
  return (
    <Container>
      <Column>
        <ColumnHeader>Capital Utilization per Hour</ColumnHeader>
        <ColumnBody>
          <ColumnBodyLineGroup>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MEA_T: </Label>
                <Value>{simultanityData.mean.toFixed(2)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>STD_T: </Label>
                <Value>{simultanityData.sdev.toFixed(2)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MIS_T: </Label>
                <Value>{`${
                  simultanityData.missed
                } | ${simultanityData.cutoff.toFixed(0)}%`}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MIN_T: </Label>
                <Value>{simultanityData.min}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MED_T: </Label>
                <Value>{simultanityData.median}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MAX_T: </Label>
                <Value>{simultanityData.max}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          </ColumnBodyLineGroup>
          <ColumnBodyLineGroup>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MEA_U: </Label>
                <Value>{formatPercentage(simultanityData.meanUtil)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>STD_U: </Label>
                <Value>{formatPercentage(simultanityData.sdevUtil)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>POS_U: </Label>
                <Value>{formatPercentage(simultanityData.possibleUtil)}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MIN_U: </Label>
                <Value>{formatPercentage(simultanityData.minUtil)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MED_U: </Label>
                <Value>{formatPercentage(simultanityData.medianUtil)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MAX_U: </Label>
                <Value>{formatPercentage(simultanityData.maxUtil)}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          </ColumnBodyLineGroup>
        </ColumnBody>
      </Column>
      <Column>
        <ColumnHeader>Util Groups</ColumnHeader>
        <ColumnBodyLineGroup>
          <VerticalTableContainer>
            <Scrollbars style={{ height: "86px" }}>
              <VerticalTable>
                {simultanityData.simList.map((group) => (
                  <VerticalTableElement key={group.l + group.value}>
                    <Label>{group.l}:</Label>
                    <Value>{group.value}</Value>
                  </VerticalTableElement>
                ))}
              </VerticalTable>
            </Scrollbars>
          </VerticalTableContainer>
        </ColumnBodyLineGroup>
      </Column>
    </Container>
  );
};

export default SimultanityAnalysis;
