import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

const TenKTable = ({ tenKList }) => {
  const takenProfitWeekly =
    tenKList.takenProfitWeekly +
    tenKList.days[tenKList.days.length - 1].balanceWeekly -
    tenKList.startingMarginBalance;
  const takenProfitMonthly =
    tenKList.takenProfitMonthly +
    tenKList.days[tenKList.days.length - 1].balanceMonthly -
    tenKList.startingMarginBalance;
  const takenProfitYearly =
    tenKList.takenProfitYearly +
    tenKList.days[tenKList.days.length - 1].balanceYearly -
    tenKList.startingMarginBalance;
  const takenProfitAllTime =
    tenKList.days[tenKList.days.length - 1].balanceAllTime -
    tenKList.startingMarginBalance;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="sum-area"></TableCell>
            <TableCell className="sum-area" align="right">
              WEEKLY TP
            </TableCell>
            <TableCell className="sum-area" align="right">
              MONTHLY TP
            </TableCell>
            <TableCell className="sum-area" align="right">
              YEARLY TP
            </TableCell>
            <TableCell className="sum-area" align="right">
              ALL-TIME
            </TableCell>
            <TableCell className="sum-area" align="right">
              POSITIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="sum-area" scope="row" align="right">
              TOTAL PROFIT
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              {takenProfitWeekly.toLocaleString("de-DE", {
                style: "currency",
                currency: "USD",
              })}
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              {takenProfitMonthly.toLocaleString("de-DE", {
                style: "currency",
                currency: "USD",
              })}
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              {takenProfitYearly.toLocaleString("de-DE", {
                style: "currency",
                currency: "USD",
              })}
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              {takenProfitAllTime.toLocaleString("de-DE", {
                style: "currency",
                currency: "USD",
              })}
            </TableCell>
            <TableCell className="sum-area" scope="row" align="right">
              MAX | LAST
            </TableCell>
          </TableRow>
          {tenKList.days.map((day) => {
            const date = new Date(day.time);
            const isFirstOfYear = date.getMonth() === 0 && date.getDate() === 1;
            const isFirstOfMonth = date.getDate() === 1;
            const isFirstOfWeek = date.getDay() === 1;
            return (
              <TableRow key={day.time}>
                <TableCell scope="row" align="right" className="sum-area">
                  {date.toISOString().split("T")[0]}
                </TableCell>
                <TableCell
                  align="right"
                  className={isFirstOfWeek ? "isFirst" : ""}
                >
                  {day.balanceWeekly.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "USD",
                  })}
                </TableCell>
                <TableCell
                  align="right"
                  className={isFirstOfMonth ? "isFirst" : ""}
                >
                  {day.balanceMonthly.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "USD",
                  })}
                </TableCell>
                <TableCell
                  align="right"
                  className={isFirstOfYear ? "isFirst" : ""}
                >
                  {day.balanceYearly.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "USD",
                  })}
                </TableCell>
                <TableCell align="right">
                  {day.balanceAllTime.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "USD",
                  })}
                </TableCell>
                <TableCell align="right">
                  {day.maxOpenPositions} | {day.lastOpenPositions}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TenKTable;
