const getBaseOptionsOfChart = (shownSymbolData, chartData) => {
  return {
    title: {
      text: "",
    },
    rangeSelector: {
      selected: 1,
    },
    plotOptions: {
      candlestick: {
        color: "#d9a8a8",
        upColor: "#add9a8",
        lineColor: "#111",
        lineWidth: 2,
      },
    },
    chart: {
      text: "",
      zoomType: "x",
      backgroundColor: "#1b3342",
      style: { fontFamily: "Fira Code" },
    },
    xAxis: {
      type: "datetime",
      lineColor: "#061b27",
      tickInterval: 3600 * 1000,
      tickPosition: "inside",
      tickColor: "#061b27",
      labels: {
        style: {
          color: "#eee",
        },
      },
    },
    yAxis: [
      {
        gridLineColor: "#061b27",
        title: {
          text: "OHLC",
        },
        labels: {
          style: {
            color: "#eee",
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "candlestick",
        data: chartData.candles,
        id: "trade",
        name: shownSymbolData.trade.symbol,
        dataGrouping: {
          units: [["hour"]],
        },
        zIndex: 999,
      },
    ],
  };
};

export default getBaseOptionsOfChart;
