import React from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { ReactComponent as Logo } from "../../_assets/logo/logo.svg";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #072130;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 9px;
`;
const Header = styled.h1`
  margin: 50px 0;
`;
const TeaserText = styled.span`
  font-size: 16px;
  margin: 30px 0 60px 0;
`;

const StyledLogo = styled(Logo)`
  width: 300px;
  height: auto;
  margin-left: auto;
`;
const StyledBackground = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/bg-01.jpg);
`;

const PreReleaseCurtain = ({ setLoggedIn }) => {
  const onChange = (input) => {
    if (input === "million") setLoggedIn(true);
  };

  return (
    <Container>
      <InnerContainer>
        <StyledLogo></StyledLogo>
        <Header>
          investiguana <br></br> - financial market intelligence for everybody!
        </Header>
        <TeaserText>
          For decades, hedge funds and professional investment firms have been
          the sole profiteers from advanced data and financial market analyses.
          Until today. By integrating cutting edge analytical methods, the power
          of serverless technology, and the limitless potential of artificial
          intelligence we're uncovering unique market insights and delivering
          them to traders all around the globe. <br></br>
          <br></br>
          Informed investment decisions are our mission, for seasoned
          professional as well as first time retail investors. Data-driven
          insights will provide everybody with a unique edge in the financial
          markets.
          <br></br>
          <br></br>
          Stay tuned for updates on our progress and be among the first to
          experience the future of financial market analysis.
        </TeaserText>
        <StyledBackground></StyledBackground>
      </InnerContainer>
      <TextField
        type="password"
        onChange={(e) => onChange(e.target.value)}
      ></TextField>
    </Container>
  );
};

export default PreReleaseCurtain;
