import React from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CopyCode from "../CopyCode/CopyCode";

const Container = styled.div`
  flex: 7;
  .MuiTableContainer-root {
    max-height: 90vh;
    overflow-y: auto;

    .MuiTableRow-root {
      vertical-align: baseline;
    }
    .MuiTableCell-stickyHeader {
      background-color: #072130;
    }
  }
  button {
    position: absolute;
    top: 9px;
    right: 9px;
  }
`;

const InCellOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ResultTable = ({ resultList, totalCount }) => {
  return (
    <Container>
      <CopyCode resultList={resultList}></CopyCode>
      {resultList.length && (
        <>
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">TPM</TableCell>
                  <TableCell align="right">M</TableCell>
                  <TableCell align="right">M90</TableCell>
                  <TableCell align="right">MSD</TableCell>
                  <TableCell align="right">WR</TableCell>
                  <TableCell align="right">M90PH</TableCell>
                  <TableCell align="right">M90PD</TableCell>
                  <TableCell align="right">DUR</TableCell>
                  <TableCell align="right">DURSD</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultList.map((result) => (
                  <TableRow key={result.ID}>
                    <TableCell scope="row">
                      <InCellOverviewContainer>
                        <span>{result.ID}</span>
                        <span>Last calculated: {result.LCALC}</span>
                        <span>Level: {result.L}</span>
                        <span>Total number of months: {result.MONTHS}</span>
                        <br></br>
                        <div>{JSON.stringify(result.config)},</div>
                        <br></br>
                      </InCellOverviewContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>3M</span>
                        <span>12M</span>
                        <span>1907</span>
                        <span>TM</span>
                        <span>ALL</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.TPM_3M}</span>
                        <span>{result.TPM_12M}</span>
                        <span>{result.TPM_1907}</span>
                        <span>{result.TPM_TM}</span>
                        <span>{result.TPM_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.M_3M}</span>
                        <span>{result.M_12M}</span>
                        <span>{result.M_1907}</span>
                        <span>{result.M_TM}</span>
                        <span>{result.M_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.M90_3M}</span>
                        <span>{result.M90_12M}</span>
                        <span>{result.M90_1907}</span>
                        <span>{result.M90_TM}</span>
                        <span>{result.M90_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.MSD_3M}</span>
                        <span>{result.MSD_12M}</span>
                        <span>{result.MSD_1907}</span>
                        <span>{result.MSD_TM}</span>
                        <span>{result.MSD_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.WR_3M}</span>
                        <span>{result.WR_12M}</span>
                        <span>{result.WR_1907}</span>
                        <span>{result.WR_TM}</span>
                        <span>{result.WR_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.M90PH_3M}</span>
                        <span>{result.M90PH_12M}</span>
                        <span>{result.M90PH_1907}</span>
                        <span>{result.M90PH_TM}</span>
                        <span>{result.M90PH_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.M90PD_3M}</span>
                        <span>{result.M90PD_12M}</span>
                        <span>{result.M90PD_1907}</span>
                        <span>{result.M90PD_TM}</span>
                        <span>{result.M90PD_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.DUR_3M}</span>
                        <span>{result.DUR_12M}</span>
                        <span>{result.DUR_1907}</span>
                        <span>{result.DUR_TM}</span>
                        <span>{result.DUR_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                    <TableCell scope="row">
                      <InCellContainer>
                        <span>{result.DURSD_3M}</span>
                        <span>{result.DURSD_12M}</span>
                        <span>{result.DURSD_1907}</span>
                        <span>{result.DURSD_TM}</span>
                        <span>{result.DURSD_ALL}</span>
                      </InCellContainer>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            Showing {resultList.length} of {totalCount}
          </div>
        </>
      )}
    </Container>
  );
};

export default ResultTable;
