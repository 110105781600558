import React from "react";
import AnalysisList from "../Components/AnalysisList/AnalysisList";
import UpperContainer from "../Components/UpperContainer";
import BottomContainer from "../Components/BottomContainer";
import StrategyOverview from "../Components/StrategyOverview/StrategyOverview";
import ExchangeContainer from "../Components/ExchangeContainer";
import TradeList from "../Components/TradeList/TradeList";
import OpenPositions from "../Components/OpenPositions/OpenPositions";

const KucoinOverview = () => {
  return (
    <ExchangeContainer>
      <UpperContainer>
        <StrategyOverview></StrategyOverview>
      </UpperContainer>
      <BottomContainer>
        <OpenPositions></OpenPositions>
        <TradeList></TradeList>
        <AnalysisList></AnalysisList>
      </BottomContainer>
    </ExchangeContainer>
  );
};

export default KucoinOverview;
