import React, { useState } from "react";
import {
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CodeEditorHeader from "./CodeEditorHeader";
import styled from "styled-components";
import BacktestListItem from "./BacktestListItem";
import Scrollbars from "react-custom-scrollbars";
import { Refresh } from "@material-ui/icons";

const Container = styled.div`
  margin: 12px;
  max-width: 300px;
`;

const InnerContainer = styled.div``;

const SymbolContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 300px;
  .MuiFilledInput-root {
    padding-top: 29px;
  }
`;

const DateContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    margin: 12px 0 12px 0;
  }
`;

const ReloadButtonAndProgressContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  margin-bottom: 12px;
  button {
    position: absolute;
    top: 3px;
    right: 12px;
  }
  .MuiLinearProgress-root {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
`;

const BacktestControls = ({
  strategyCode,
  startBacktest,
  collectedSymbols,
  backtestsOfViewedStrategy,
  loadBacktestResults,
  loadingBacktestResults,
  viewBacktestResults,
}) => {
  const [startDate, setStartDate] = useState(new Date("2/1/2023"));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [hardStopLoss, setHardStopLoss] = useState(-0.02);
  const [hardStopDuration, setHardStopDuration] = useState(100);
  const [trendCheckForTrades, setTrendCheckForTrades] = useState({
    enabled: true,
    data: { hours: 2, threshold: 0 },
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const autoFillSymbols = () => {
    const regexForSymbolArrays = /{"type":"symbols","value":\[(.*?)\]}/g;
    const matches = strategyCode.config.match(regexForSymbolArrays);
    const arrays = matches.map((match) => JSON.parse(match).value);
    const symbols = arrays.flat();
    const uniqueSymbols = [...new Set(symbols)];
    setSelectedSymbols(uniqueSymbols);
  };

  return (
    <Container>
      <CodeEditorHeader>Backtest Controls</CodeEditorHeader>
      <InnerContainer>
        <SymbolContainer>
          <StyledAutocomplete
            multiple
            id="tags-filled"
            options={collectedSymbols.map(
              (symbol) => `${symbol.exchange}_${symbol.type}-${symbol.symbol}`
            )}
            value={selectedSymbols}
            onChange={(event, newValue) => {
              setSelectedSymbols(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" label="Symbols" />
            )}
          />
          <Button size="small" onClick={() => autoFillSymbols()}>
            Auto-Fill from Config
          </Button>
        </SymbolContainer>
        <DateContainer>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              color="secondary"
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              id="date-picker-start"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </MuiPickersUtilsProvider>
        </DateContainer>
        <DateContainer>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              color="secondary"
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              id="date-picker-end"
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </MuiPickersUtilsProvider>
        </DateContainer>
        <br></br>
        <TextField
          color="secondary"
          label="Hard Stop Loss"
          fullWidth
          value={hardStopLoss}
          onChange={(event) => setHardStopLoss(event.target.value)}
        />
        <br></br>
        <br></br>
        <TextField
          color="secondary"
          label="Hard Stop Duration (h)"
          fullWidth
          value={hardStopDuration}
          onChange={(event) => setHardStopDuration(event.target.value)}
        />
        <br></br>
        <br></br>
        <FormControlLabel
          control={
            <Switch
              checked={trendCheckForTrades.enabled}
              onChange={(e) =>
                setTrendCheckForTrades({
                  ...trendCheckForTrades,
                  enabled: e.target.checked,
                })
              }
              name="concurrentTrades"
            />
          }
          label="Trend Check for Trades (close if < x% after yh)"
        />
        {trendCheckForTrades.enabled && (
          <>
            <br></br>
            <br></br>
            <TextField
              color="secondary"
              label="Trend Check: Hours (h)"
              fullWidth
              value={trendCheckForTrades.data.hours}
              onChange={(e) =>
                setTrendCheckForTrades({
                  enabled: true,
                  data: {
                    threshold: trendCheckForTrades.data.threshold,
                    hours: e.target.value,
                  },
                })
              }
            />
            <br></br>
            <br></br>
            <TextField
              color="secondary"
              label="Trend Check: Threshold (Dec.)"
              fullWidth
              value={trendCheckForTrades.data.threshold}
              onChange={(e) =>
                setTrendCheckForTrades({
                  enabled: true,
                  data: {
                    threshold: e.target.value,
                    hours: trendCheckForTrades.data.hours,
                  },
                })
              }
            />
          </>
        )}
        <br></br>
        <br></br>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedSymbols.length === 0 || hardStopLoss >= 0}
            onClick={() =>
              startBacktest({
                ...strategyCode,
                selectedSymbols,
                startDate,
                endDate,
                hardStopDuration,
                hardStopLoss,
                trendCheckForTrades,
              })
            }
          >
            Start Backtest
          </Button>
        </ButtonContainer>
      </InnerContainer>
      <InnerContainer>
        <ReloadButtonAndProgressContainer>
          <IconButton size="small" onClick={() => loadBacktestResults()}>
            <Refresh fontSize="inherit"></Refresh>
          </IconButton>
          {loadingBacktestResults && (
            <LinearProgress color="secondary"></LinearProgress>
          )}
        </ReloadButtonAndProgressContainer>
        <Scrollbars style={{ height: "300px" }}>
          {backtestsOfViewedStrategy
            .sort((a, b) => b.creationDate.localeCompare(a.creationDate))
            .map((bt) => (
              <BacktestListItem
                key={bt.id}
                backtest={bt}
                viewBacktestResults={viewBacktestResults}
              ></BacktestListItem>
            ))}
        </Scrollbars>
      </InnerContainer>
    </Container>
  );
};

export default BacktestControls;
