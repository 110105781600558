import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { CheckOutlined, DeleteOutline, EditOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  padding-right: 30px;
  overflow-y: auto;
  max-height: 85vh;
`;

const FiltersContainer = styled.div`
  margin: 0 0 60px 0;
`;
const SortingContainer = styled.div`
  margin: 0 0 60px 0;
`;

const ExistingFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    padding: 16px;
    border-radius: 4px;
    background-color: #072130;
    margin: 6px 0;
    position: relative;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 3px;
  top: 25%;
  bottom: 25%;
`;

const AddFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: rgba(224, 241, 251, 0.28);
  border-radius: 4px;
`;

const AddSortContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: rgba(224, 241, 251, 0.28);
  border-radius: 4px;
`;

const FilterPanel = ({ loadWelfiResults }) => {
  const [filters, setFilters] = useState([]);
  const [excludeFilters, setExcludeFilters] = useState([]);
  const [sorts, setSorts] = useState([]);

  const [currentFilter, setCurrentFilter] = useState("");
  const [currentFilterValue, setCurrentFilterValue] = useState("");
  const [currentExcludeFilter, setCurrentExcludeFilter] = useState("");
  const [currentExcludeFilterValue, setCurrentExcludeFilterValue] =
    useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [currentSortDirection, setCurrentSortDirection] = useState("");
  const [editingFilterIndex, setEditingFilterIndex] = useState(null);
  const [editingFilterValue, setEditingFilterValue] = useState("");

  const addFilter = () => {
    const isMultipleFilter = currentFilter.split("_")[1] === "TA";
    if (isMultipleFilter) {
      const filterType = currentFilter.split("_")[0];
      setFilters([
        ...filters,
        {
          filter: `${filterType}_3M`,
          filterValue: currentFilterValue,
        },
        {
          filter: `${filterType}_12M`,
          filterValue: currentFilterValue,
        },
        {
          filter: `${filterType}_1907`,
          filterValue: currentFilterValue,
        },
        {
          filter: `${filterType}_ALL`,
          filterValue: currentFilterValue,
        },
      ]);
    } else {
      setFilters([
        ...filters,
        {
          filter: currentFilter,
          filterValue: currentFilterValue,
        },
      ]);
    }
    setCurrentFilter("");
    setCurrentFilterValue("");
  };

  const removeFilter = (filterToBeRemoved) => {
    const newFilters = [
      ...filters.filter(
        (currentFilter) =>
          currentFilter.filter !== filterToBeRemoved.filter ||
          currentFilter.filterValue !== filterToBeRemoved.filterValue
      ),
    ];
    setFilters(newFilters);
  };

  const addExcludeFilter = () => {
    setExcludeFilters([
      ...excludeFilters,
      {
        filter: currentExcludeFilter,
        filterValue: currentExcludeFilterValue,
      },
    ]);
    setCurrentExcludeFilter("");
    setCurrentExcludeFilterValue("");
  };

  const removeExcludeFilter = (filterToBeRemoved) => {
    const newFilters = [
      ...excludeFilters.filter(
        (currentFilter) =>
          currentFilter.filter !== filterToBeRemoved.filter ||
          currentFilter.filterValue !== filterToBeRemoved.filterValue
      ),
    ];
    setExcludeFilters(newFilters);
  };

  const editFilter = (filterToBeEdited) => {
    const index = filters.findIndex(
      (currentFilter) =>
        currentFilter.filter === filterToBeEdited.filter &&
        currentFilter.filterValue === filterToBeEdited.filterValue
    );
    setEditingFilterValue(filters[index].filterValue);
    setEditingFilterIndex(index);
  };

  const finishEditingFilter = () => {
    const newFilters = [...filters];
    newFilters.splice(editingFilterIndex, 1, {
      filter: filters[editingFilterIndex].filter,
      filterValue: editingFilterValue,
    });
    setFilters(newFilters);
    setEditingFilterIndex(null);
  };

  const addSort = () => {
    setSorts([
      ...sorts,
      {
        sort: currentSort,
        sortDirection: currentSortDirection,
      },
    ]);
    setCurrentSort("");
    setCurrentSortDirection("");
  };

  const removeSort = (sortToBeRemoved) => {
    const newSorts = [
      ...sorts.filter(
        (currentSort) =>
          currentSort.sort !== sortToBeRemoved.sort ||
          currentSort.sortDirection !== sortToBeRemoved.sortDirection
      ),
    ];
    setSorts(newSorts);
  };

  const filterOptions = [
    "TPM_TA",
    "M_TA",
    "MSD_TA",
    "M90_TA",
    "WR_TA",
    "DUR_TA",
    "DURSD_TA",
    "M90PH_TA",
    "M90PD_TA",
    "TPM_3M",
    "M_3M",
    "MSD_3M",
    "M90_3M",
    "WR_3M",
    "DUR_3M",
    "DURSD_3M",
    "M90PH_3M",
    "M90PD_3M",
    "TPM_12M",
    "M_12M",
    "MSD_12M",
    "M90_12M",
    "WR_12M",
    "DUR_12M",
    "DURSD_12M",
    "M90PH_12M",
    "M90PD_12M",
    "TPM_1907",
    "M_1907",
    "MSD_1907",
    "M90_1907",
    "WR_1907",
    "DUR_1907",
    "DURSD_1907",
    "M90PH_1907",
    "M90PD_1907",
    "TPM_TM",
    "M_TM",
    "MSD_TM",
    "M90_TM",
    "WR_TM",
    "DUR_TM",
    "DURSD_TM",
    "M90PH_TM",
    "M90PD_TM",
    "TPM_ALL",
    "M_ALL",
    "MSD_ALL",
    "M90_ALL",
    "WR_ALL",
    "DUR_ALL",
    "DURSD_ALL",
    "M90PH_ALL",
    "M90PD_ALL",
    "trigger",
    "support",
    "close",
    "symbol",
  ];
  return (
    <Container>
      <FiltersContainer>
        <h3>Including Filters</h3>
        <ExistingFiltersContainer>
          {filters.map((filter, i) => (
            <div key={i}>
              {filter.filter}:
              {editingFilterIndex === i ? (
                <TextField
                  color="secondary"
                  label="Filter Value"
                  value={editingFilterValue}
                  onChange={(event) =>
                    setEditingFilterValue(event.target.value)
                  }
                />
              ) : (
                <>{filter.filterValue}</>
              )}
              <ButtonContainer>
                {editingFilterIndex === i ? (
                  <IconButton
                    size="small"
                    onClick={() => finishEditingFilter()}
                  >
                    <CheckOutlined></CheckOutlined>
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() =>
                      editFilter({
                        filter: filter.filter,
                        filterValue: filter.filterValue,
                      })
                    }
                  >
                    <EditOutlined></EditOutlined>
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() =>
                    removeFilter({
                      filter: filter.filter,
                      filterValue: filter.filterValue,
                    })
                  }
                >
                  <DeleteOutline></DeleteOutline>
                </IconButton>
              </ButtonContainer>
            </div>
          ))}
        </ExistingFiltersContainer>
        <AddFilterContainer>
          <FormControl>
            <InputLabel id="select-filter">Select Filter</InputLabel>
            <Select
              color="secondary"
              labelId="select-filter"
              value={currentFilter}
              onChange={(e) => setCurrentFilter(e.target.value)}
            >
              {filterOptions.map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            color="secondary"
            label="Filter Value"
            value={currentFilterValue}
            onChange={(event) => setCurrentFilterValue(event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => addFilter()}
            disabled={!currentFilterValue || !currentFilter}
          >
            Add Filter
          </Button>
        </AddFilterContainer>
      </FiltersContainer>
      <FiltersContainer>
        <h3>Excluding Filters</h3>
        <ExistingFiltersContainer>
          {excludeFilters.map((filter, i) => (
            <div key={i}>
              {filter.filter}: {filter.filterValue}
              <IconButton
                size="small"
                onClick={() =>
                  removeExcludeFilter({
                    filter: filter.filter,
                    filterValue: filter.filterValue,
                  })
                }
              >
                <DeleteOutline></DeleteOutline>
              </IconButton>
            </div>
          ))}
        </ExistingFiltersContainer>
        <AddFilterContainer>
          <FormControl>
            <InputLabel id="select-exclude-filter">
              Select Exclude Filter
            </InputLabel>
            <Select
              color="secondary"
              labelId="select-exclude-filter"
              value={currentExcludeFilter}
              onChange={(e) => setCurrentExcludeFilter(e.target.value)}
            >
              {["trigger", "support", "close", "symbol"].map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            color="secondary"
            label="Filter Value"
            value={currentExcludeFilterValue}
            onChange={(event) =>
              setCurrentExcludeFilterValue(event.target.value)
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => addExcludeFilter()}
            disabled={!currentExcludeFilterValue || !currentExcludeFilter}
          >
            Add Exclude Filter
          </Button>
        </AddFilterContainer>
      </FiltersContainer>
      <SortingContainer>
        <h3>Sort by</h3>
        <ExistingFiltersContainer>
          {sorts.map((sort, i) => (
            <div key={i}>
              {sort.sort}: {sort.sortDirection}
              <IconButton
                size="small"
                onClick={() =>
                  removeSort({
                    sort: sort.sort,
                    sortDirection: sort.sortDirection,
                  })
                }
              >
                <DeleteOutline></DeleteOutline>
              </IconButton>
            </div>
          ))}
        </ExistingFiltersContainer>
        <AddSortContainer>
          <FormControl>
            <InputLabel id="select-sort">Select Sort KPI</InputLabel>
            <Select
              color="secondary"
              labelId="select-sort"
              value={currentSort}
              onChange={(e) => setCurrentSort(e.target.value)}
            >
              {filterOptions
                .filter((f) => f.indexOf("_TA") < 0)
                .map((filter) => (
                  <MenuItem key={filter} value={filter}>
                    {filter}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-sort-direction">
              Select Sort Direction
            </InputLabel>
            <Select
              color="secondary"
              labelId="select-sort-direction"
              value={currentSortDirection}
              onChange={(e) => setCurrentSortDirection(e.target.value)}
            >
              <MenuItem key="DESC" value="DESC">
                Descending
              </MenuItem>
              <MenuItem key="ASC" value="ASC">
                Ascending
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            disabled={!currentSortDirection || !currentSort}
            onClick={() => addSort()}
          >
            Add Sort Rule
          </Button>
        </AddSortContainer>
      </SortingContainer>
      <Button
        variant="contained"
        fullWidth
        color="secondary"
        onClick={() =>
          loadWelfiResults({
            filters: filters,
            excludeFilters: excludeFilters,
            sorts: sorts,
            limit: 1000,
            count: true,
          })
        }
      >
        Start Search
      </Button>
    </Container>
  );
};

export default FilterPanel;
