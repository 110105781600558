import React, { useEffect, useState } from "react";
import StrategyEditing from "./StrategyEditing/StrategyEditing";
import styled from "styled-components";
import ExistingStrategiesList from "./ExistingStrategiesList/ExistingStrategiesList";
import axios from "axios";
import BacktestResultDialog from "./BacktestResultDialog/BacktestResultDialog";
import { Dialog, DialogContent, LinearProgress } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex: 2;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .MuiLinearProgress-root {
    width: 400px;
  }
`;
const BacktestingModule = () => {
  const [shownBacktestResults, setShownBacktestResults] = useState([]);
  const [loadingStartOfBacktest, setLoadingStartOfBacktest] = useState(false);
  const [historicalData, setHistoricalData] = useState([]);
  const [currentlyViewedStrategy, setCurrentlyViewedStrategy] = useState(null);
  const [loadedVersionOfViewedStrategy, setLoadedVersionOfViewedStrategy] =
    useState(null);

  const [collectedSymbols, setCollectedSymbols] = useState([]);
  const [loadingCurrentlyViewedStrategy, setLoadingCurrentlyViewedStrategy] =
    useState(false);
  const [backtestsOfViewedStrategy, setBacktestsOfViewedStrategy] = useState(
    []
  );
  const [loadingBacktestResults, setLoadingBacktestResults] = useState(false);
  const [loadingSingleBacktestResult, setLoadingSingleBacktestResult] =
    useState(false);

  const startBacktest = async ({
    selectedSymbols,
    startDate,
    endDate,
    openingDecision,
    closingDecision,
    config,
    hardStopDuration,
    hardStopLoss,
    trendCheckForTrades,
  }) => {
    setLoadingStartOfBacktest(true);
    const startDateDate = new Date(startDate);
    const startDateUTC = Date.UTC(
      startDateDate.getFullYear(),
      startDateDate.getMonth(),
      startDateDate.getDate()
    );
    const endDateDate = new Date(endDate);
    const endDateUTC = Date.UTC(
      endDateDate.getFullYear(),
      endDateDate.getMonth(),
      endDateDate.getDate()
    );

    if (currentlyViewedStrategy.strategy.id) {
      axios.post(`backtest-coordinator/start-backtest`, {
        strategyId: currentlyViewedStrategy.strategy.id,
        fullIdentifiers: selectedSymbols,
        startTime: startDateUTC,
        endTime: endDateUTC,
        hardStopDuration,
        hardStopLoss,
        trendCheckForTrades,
      });
    } else {
      if (
        loadedVersionOfViewedStrategy.code.openingDecision !==
          openingDecision ||
        loadedVersionOfViewedStrategy.code.closingDecision !==
          closingDecision ||
        loadedVersionOfViewedStrategy.code.config !== config
      ) {
        await axios.post(
          `strategy-deployment-manager/update-ephemeral-strategy`,
          {
            openingDecision,
            closingDecision,
            config,
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 20000)); // Ensure that function is updated
        setLoadedVersionOfViewedStrategy({
          code: {
            openingDecision: openingDecision,
            closingDecision: closingDecision,
            config: config,
          },
        });
      }
      axios.post(`backtest-coordinator/start-backtest`, {
        strategyId: "_EPHEMERAL",
        fullIdentifiers: selectedSymbols,
        startTime: startDateUTC,
        endTime: endDateUTC,
        hardStopDuration,
        hardStopLoss,
        trendCheckForTrades,
        code: { openingDecision, closingDecision, config },
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Ensure that function is updated
    await loadBacktestResults();
    setLoadingStartOfBacktest(false);
  };

  const saveStrategy = async ({
    name = new Date(),
    description = new Date(),
    openingDecision,
    closingDecision,
    config,
  }) => {
    // In the frontend, this call will fail due to the timeout in API Gateway.
    await axios.post(
      `strategy-deployment-manager/deploy-new-strategy-function`,
      {
        name,
        description,
        openingDecision,
        closingDecision,
        config,
      }
    );
  };

  const loadBacktestResults = async () => {
    setLoadingBacktestResults(true);
    const strategyId = currentlyViewedStrategy?.strategy?.id ?? "_EPHEMERAL";
    const { data: backtests } = await axios.get(
      `backtest-result-manager/backtest-result-list/${strategyId}/all-backtests`
    );
    setBacktestsOfViewedStrategy(
      backtests.sort((a, b) => a.creationDate - b.creationDate)
    );
    setLoadingBacktestResults(false);
  };

  const viewBacktestResults = async (backtestId) => {
    setLoadingSingleBacktestResult(true);
    const { data: backtest } = await axios.get(
      `backtest-result-manager/backtest-result/${
        currentlyViewedStrategy.strategy.id || "_EPHEMERAL"
      }/${backtestId}`
    );

    for (const [index, currentResult] of backtest.results.entries()) {
      const { data: currentResultData } = await axios.get(
        currentResult.resultUrl
      );
      backtest.results[index].results = currentResultData;
    }
    if (currentlyViewedStrategy?.strategy?.id) {
      backtest.config = currentlyViewedStrategy.code.config;
    } else {
      const { data: config } = await axios.get(backtest.code);
      backtest.config = config;
    }
    const { data: historical } = await axios.get(
      `data-provider/historical-symbol-data-daily-closes/`,
      {
        params: {
          symbol: "BIN_SPOT-BTCUSDT",
          startTime: backtest.startTime,
          endTime: backtest.endTime,
        },
      }
    );
    setShownBacktestResults(backtest);
    setHistoricalData(historical);
    setLoadingSingleBacktestResult(false);
  };

  const viewStrategy = async ({ id }) => {
    setBacktestsOfViewedStrategy([]);
    setLoadingCurrentlyViewedStrategy(true);
    if (id) {
      const { data: strategy } = await axios.get(
        `strategy-manager/strategy-function/${id}`
      );
      setCurrentlyViewedStrategy(strategy);
    } else {
      const { data: strategy } = await axios.get(
        `strategy-manager/ephemeral-strategy/`
      );
      setCurrentlyViewedStrategy({
        ...strategy,
        strategy: { id: null, name: "", description: "" },
      });
      setLoadedVersionOfViewedStrategy({
        ...strategy,
      });
    }
    setLoadingCurrentlyViewedStrategy(false);
  };

  const copyStrategy = async (id) => {
    setLoadingCurrentlyViewedStrategy(true);
    const { data: strategy } = await axios.get(
      `strategy-manager/strategy-function/${id}`
    );
    setCurrentlyViewedStrategy({
      backtests: [],
      code: strategy.code,
      strategy: {
        id: null,
        name: `Copy of ${strategy.strategy.name}`,
        description: `Copy of ${strategy.strategy.name}`,
      },
    });
    setLoadingCurrentlyViewedStrategy(false);
  };

  const deleteStrategy = async (id) => {
    await axios.delete(`strategy-deployment-manager/delete-strategy-function`, {
      data: { id: id },
    });
  };

  useEffect(() => {
    const loadSymbolData = async () => {
      const { data: symbols } = await axios.get(
        `strategy-manager/collected-symbols`
      );
      setCollectedSymbols(symbols);
    };
    loadSymbolData();
  }, []);

  useEffect(() => {
    loadBacktestResults(currentlyViewedStrategy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyViewedStrategy]);

  return (
    <Container>
      <ExistingStrategiesList
        viewStrategy={viewStrategy}
        deleteStrategy={deleteStrategy}
        copyStrategy={copyStrategy}
      ></ExistingStrategiesList>
      {currentlyViewedStrategy && !loadingCurrentlyViewedStrategy ? (
        <StrategyEditing
          startBacktest={startBacktest}
          saveStrategy={saveStrategy}
          collectedSymbols={collectedSymbols}
          currentlyViewedStrategy={currentlyViewedStrategy}
          backtestsOfViewedStrategy={backtestsOfViewedStrategy}
          loadBacktestResults={loadBacktestResults}
          loadingBacktestResults={loadingBacktestResults}
          viewBacktestResults={viewBacktestResults}
        ></StrategyEditing>
      ) : (
        <EmptyStateContainer>
          {loadingCurrentlyViewedStrategy && (
            <LinearProgress color="secondary"></LinearProgress>
          )}
        </EmptyStateContainer>
      )}
      <BacktestResultDialog
        loadingSingleBacktestResult={loadingSingleBacktestResult}
        shownBacktestResults={shownBacktestResults}
        setShownBacktestResults={setShownBacktestResults}
        currentlyViewedStrategy={currentlyViewedStrategy}
        historicalData={historicalData}
      ></BacktestResultDialog>
      <Dialog open={loadingStartOfBacktest}>
        <DialogContent>
          <div>Updating strategy function and starting backtest.</div>
          <br></br>
          <LinearProgress color="secondary"></LinearProgress>
          <br></br>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default BacktestingModule;
