import React from "react";
import styled from "styled-components";
import formatPositionType from "../../../../../../Common/Functions/formatPositionType";
import shortenFullIdentifier from "../../../../../../Common/Functions/shortenFullIdentifier";
import formatAmount from "../../../../../../Common/Functions/formatAmount";

const Container = styled.div`
  padding: 12px;
  background-color: #061b27;
  border-radius: 4px;
  margin: 12px;
  position: relative;
  .long,
  .long_concurrent,
  .short_concurrent,
  .short {
    position: absolute;
    right: 12px;
    top: 6px;
  }
`;

const Detail = styled.div`
  font-size: 12px;
  opacity: 50%;
`;

const OpenPositionItem = ({ position }) => {
  const tradeResponseParsed = JSON.parse(position.tradeResponse);
  const stopOrderResponseParsed = JSON.parse(position.stopOrderResponse);
  const formattedTime = `${
    new Date(position.entryTime).toISOString().split("T")[0]
  }
    |  
  ${new Date(position.entryTime).toISOString().split("T")[1].substring(0, 8)}`;

  return (
    <Container>
      <div>{formatPositionType(position.type)}</div>
      <div>{formattedTime}</div>
      <div>
        {shortenFullIdentifier(position.symbol)} | Strategy:{" "}
        {position.openingStrategyConfig}
      </div>
      <div>Entry Price: {position.entryPrice}</div>
      <div>
        Size: {formatAmount(position.sizeUSDT, 2)} USDT |{" "}
        {formatAmount(position.sizeCryptoRounded, 0)}{" "}
        {shortenFullIdentifier(position.symbol)}
      </div>
      <br></br>
      <Detail>Trade: {position.tradeId}</Detail>
      <Detail>Decision: {position.id}</Detail>
      <Detail>
        TradeReq: {tradeResponseParsed.code} |{" "}
        {tradeResponseParsed.data?.orderId}
        {tradeResponseParsed.msg}
      </Detail>
      <Detail>
        StopReq: {stopOrderResponseParsed.code} |{" "}
        {stopOrderResponseParsed.data?.orderId}
        {stopOrderResponseParsed.msg}
      </Detail>
    </Container>
  );
};

export default OpenPositionItem;
