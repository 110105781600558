import React from "react";
import styled from "styled-components";
import formatPercentage from "../../../../../../Common/Functions/formatPercentage";
import shortenFullIdentifier from "../../../../../../Common/Functions/shortenFullIdentifier";

const Container = styled.div`
  padding: 6px;
  margin: 3px 0;
  background-color: #061b27;
  border-radius: 4px;
`;

const TradeListItem = ({ trade }) => {
  return (
    <Container>
      <div>{shortenFullIdentifier(trade.symbol)}</div>
      <div>
        {trade.source} | {trade.type}
      </div>
      <div>{trade.openingStrategyConfig || <>&nbsp;</>}</div>
      <div>
        {trade.profit ? (
          <>
            {trade.price.toLocaleString("de-DE", {
              minimumFractionDigits: 4,
            })}{" "}
            | {formatPercentage(trade.profit)}
          </>
        ) : (
          <>
            {trade.price.toLocaleString("de-DE", {
              minimumFractionDigits: 4,
            })}
          </>
        )}
      </div>
    </Container>
  );
};

export default TradeListItem;
