const MS_PER_HOUR = 1000 * 60 * 60;

const getTradeList = (
  backtestResultSymbols,
  comission,
  concurrentTrades,
  strategies = null,
  leverage,
  includeOpenPositions = false
) => {
  const MAINTENANCE_MARGIN = 0.025;
  const liquidationThreshold =
    (Math.round((1 / leverage - MAINTENANCE_MARGIN) * 1000) / 1000) * -1;
  let tradeList = [];
  backtestResultSymbols.forEach((symbol) => {
    symbol.results.forEach((item, index) => {
      if (item.closingDecisions.length > 0) {
        if (index === 0) return;
        item.closingDecisions.forEach((d) => {
          const indexOfOpening = tradeList.findIndex(
            (t) => t.id === d.id && t.symbol === symbol.symbol
          );
          tradeList[indexOfOpening].grossProfit = d.profit;
          tradeList[indexOfOpening].netProfit = d.profit - comission;
          tradeList[indexOfOpening].closeTime = item.candle.closeTime;
          if (!d.intraHourClose) {
            tradeList[indexOfOpening].closePrice = item.candle.close;
          } else {
            if (tradeList[indexOfOpening].type.indexOf("OPEN_LONG") === 0) {
              tradeList[indexOfOpening].closePrice =
                tradeList[indexOfOpening].entryPrice +
                d.profit * tradeList[indexOfOpening].entryPrice;
            } else if (
              tradeList[indexOfOpening].type.indexOf("OPEN_SHORT") === 0
            ) {
              tradeList[indexOfOpening].closePrice =
                tradeList[indexOfOpening].entryPrice -
                d.profit * tradeList[indexOfOpening].entryPrice;
            }
          }
          tradeList[indexOfOpening].closeReason = d.reason;
          tradeList[indexOfOpening].durationHours =
            (item.candle.closeTime - tradeList[indexOfOpening].openTime) /
            MS_PER_HOUR;
          tradeList[indexOfOpening].liquidated =
            d.highestUnrealizedLoss.l <= liquidationThreshold &&
            !d.intraHourClose;

          if (tradeList[indexOfOpening].liquidated) {
            tradeList[indexOfOpening].grossProfit =
              liquidationThreshold - MAINTENANCE_MARGIN;
            tradeList[indexOfOpening].netProfit =
              tradeList[indexOfOpening].grossProfit - comission;
            tradeList[indexOfOpening].closeTime = d.highestUnrealizedLoss.t;
            tradeList[indexOfOpening].closePrice = d.highestUnrealizedLoss.p;
            tradeList[indexOfOpening].closeReason = "LIQUIDATED";
            tradeList[indexOfOpening].durationHours =
              (d.highestUnrealizedLoss.t - tradeList[indexOfOpening].openTime) /
              MS_PER_HOUR;
          }
        });
      }
      if (item.openingDecisions.length > 0) {
        if (index > symbol.results.length - 1) return;
        item.openingDecisions.forEach((d) => {
          tradeList.push({
            id: d.id,
            openTime: item.candle.closeTime,
            entryPrice: item.candle.close,
            type: d.openingDecision,
            symbol: symbol.symbol,
            openingStrategyConfig: d.openingStrategyConfig,
          });
        });
      }
    });
  });
  if (strategies) {
    tradeList = tradeList.map((trade) => {
      const strategy = strategies.find(
        (s) =>
          s.find((c) => c.type === "id").value === trade.openingStrategyConfig
      );
      return {
        ...trade,
        strategy: {
          id: strategy.find((c) => c.type === "id"),
          trigger: strategy.find((c) => c.type === "trigger"),
          triggerConfigs: strategy.filter((c) => c.type === "triggerConfig"),
          support: strategy.find((c) => c.type === "support"),
          supportConfigs: strategy.filter((c) => c.type === "supportConfig"),
          close: strategy.find((c) => c.type === "close"),
          closeConfigs: strategy.filter((c) => c.type === "closeConfig"),
          direction:
            strategy.find((c) => c.type === "direction")?.value || null,
          symbols: strategy.find((c) => c.type === "symbols").value,
        },
      };
    });
  }
  const filteredTradeList = includeOpenPositions
    ? tradeList
    : tradeList.filter((t) => t.closePrice);

  const sortedtradeList = filteredTradeList
    .sort((a, b) =>
      a.openingStrategyConfig.localeCompare(b.openingStrategyConfig)
    )
    .sort((a, b) => a.openTime - b.openTime);

  const concurrencyFilteredTradeList = concurrentTrades
    ? sortedtradeList
    : sortedtradeList.filter((t) => t.type.indexOf("_CONCURRENT") === -1);
  return concurrencyFilteredTradeList;
};

export default getTradeList;
