import React from "react";
import Column from "../../../Components/SummaryComponents/Column";
import ColumnHeader from "../../../Components/SummaryComponents/ColumnHeader";
import ColumnBody from "../../../Components/SummaryComponents/ColumnBody";
import ColumnBodyLineGroup from "../../../Components/SummaryComponents/ColumnBodyLineGroup";
import ColumnBodyLine from "../../../Components/SummaryComponents/ColumnBodyLine";
import LabelValuePair from "../../../Components/SummaryComponents/LabelValuePair";
import Label from "../../../Components/SummaryComponents/Label";
import Value from "../../../Components/SummaryComponents/Value";
import styled from "styled-components";

const WideValue = styled(Value)`
  width: 180px;
`;

const TradeBaseData = ({ trade }) => {
  return (
    <Column>
      <ColumnHeader>Trade</ColumnHeader>
      <ColumnBody>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Open Time</Label>
              <WideValue>
                {new Date(trade.openTime).toISOString().split("T")[0]}
                {"  |  "}
                {new Date(trade.openTime)
                  .toISOString()
                  .split("T")[1]
                  .substring(0, 8)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Close Time</Label>
              <WideValue>
                {new Date(trade.closeTime).toISOString().split("T")[0]}
                {"  |  "}
                {new Date(trade.closeTime)
                  .toISOString()
                  .split("T")[1]
                  .substring(0, 8)}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Duration</Label>
              <Value>{trade.durationHours}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Opposed</Label>
              <Value>{trade.opposed ? "true" : "false"}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Opposing</Label>
              <Value>{trade.opposing ? "true" : "false"}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
        </ColumnBodyLineGroup>
      </ColumnBody>
    </Column>
  );
};

export default TradeBaseData;
