import React from "react";
import styled from "styled-components";
import KucoinOverview from "./ExchangeOverviews/KucoinOverview";

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 12px 0;
  padding: 12px;
`;

const ActiveTradesModule = () => {
  return (
    <OuterContainer>
      <KucoinOverview></KucoinOverview>
    </OuterContainer>
  );
};

export default ActiveTradesModule;
