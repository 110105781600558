import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { IconButton, LinearProgress } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import AreaContainer from "../../../ControllingModule/Components/AreaContainer";
import TradeListLine from "./Components/TradeListLine";
import NoDataHint from "../../../ControllingModule/Components/NoDataHint";
import HeaderContainer from "../../../ControllingModule/Components/HeaderContainer";

const Container = styled.div`
  width: 30%;
`;

const TradeList = () => {
  const [loadingTradeList, setLoadingTradeList] = useState(true);
  const [tradeList, setTradeList] = useState([]);

  const loadTradeList = async () => {
    setLoadingTradeList(true);
    const { data: tradeList } = await axios.get(
      `trading-manager-kucoin/trade-list`
    );
    setTradeList(tradeList);
    setLoadingTradeList(false);
  };

  useEffect(() => {
    loadTradeList();
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <h2>Real vs. Backtest Trades</h2>
        <IconButton onClick={() => loadTradeList()}>
          <Refresh fontSize="inherit"></Refresh>
        </IconButton>
      </HeaderContainer>
      <AreaContainer>
        {loadingTradeList ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : (
          <>
            {tradeList.sortedTrades.length ? (
              tradeList.sortedTrades.map((hour, index) => (
                <TradeListLine key={hour.time} lineData={hour}></TradeListLine>
              ))
            ) : (
              <NoDataHint>No Data</NoDataHint>
            )}
          </>
        )}
      </AreaContainer>
    </Container>
  );
};

export default TradeList;
