import React from "react";
import styled from "styled-components";
import { IconButton, LinearProgress } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import HeaderContainer from "./HeaderContainer";
import AreaContainer from "./AreaContainer";
import ControllingTable from "./ControllingTable";

const Container = styled.div`
  min-width: 95vw;
  width: 95vw;
`;

const ControllingTradeOverview = ({
  loadTradeList,
  loadingTradeList,
  tradeList,
}) => {
  return (
    <Container>
      <HeaderContainer>
        <h2>Trade List</h2>
        <IconButton onClick={() => loadTradeList()}>
          <Refresh fontSize="inherit"></Refresh>
        </IconButton>
      </HeaderContainer>
      <AreaContainer>
        {loadingTradeList ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : (
          <ControllingTable tradeList={tradeList}></ControllingTable>
        )}
      </AreaContainer>
    </Container>
  );
};

export default ControllingTradeOverview;
