const formatAmount = (amount, digits) => {
  if (!amount) return "";
  const adjustedDigits =
    digits || digits === 0 ? digits : amount >= 100 ? 2 : 4;
  return amount.toLocaleString("de-DE", {
    minimumFractionDigits: adjustedDigits,
    maximumFractionDigits: adjustedDigits,
  });
};
export default formatAmount;
