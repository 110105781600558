import getMeanNetProfitOfTrades from "./getMeanNetProfitOfTrades";
import getMaxOfMonths from "./getMaxOfMonths";
import getMaxOfTrades from "./getMaxOfTrades";
import getMinOfMonths from "./getMinOfMonths";
import getMinOfTrades from "./getMinOfTrades";
import getStandardDeviationOfMonths from "./getStandardDeviationOfMonths";
import getStandardDeviationOfTrades from "./getStandardDeviationOfTrades";
import getWinRateOfMonths from "./getWinRateOfMonths";
import getWinRateOfTrades from "./getWinRateOfTrades";
import getMeanNetProfitOfMonths from "./getMeanNetProfitOfMonths";
import getSumOfTrades from "./getSumOfTrades";
import getSumOfMonths from "./getSumOfMonths";

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const MS_PER_MIN = 1000 * 60;

const getMonthAndYearFromDate = (date) => {
  const month = date.getUTCMonth() + 1;
  const monthStr = month.toString().padStart(2, "0");
  const yearStr = date.getUTCFullYear().toString();
  return `${yearStr}-${monthStr}`;
};

const getStartTimeFromMonthAndYear = (date) =>
  new Date(`${date}-01 00:00:00:000 UTC`).getTime();

const getAllMonthsBetweenTwoDates = (startDate, endDate) => {
  const months = [];
  const startYear = startDate.getUTCFullYear();
  const endYear = endDate.getUTCFullYear();
  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : endDate.getUTCMonth();
    const startMonth = i === startYear ? startDate.getUTCMonth() : 0;
    for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const monthAndDate = getMonthAndYearFromDate(
        new Date(`${i}-${j + 1}-15`)
      );
      months.push({
        monthAndDate: monthAndDate,
        start: getStartTimeFromMonthAndYear(monthAndDate),
      });
    }
  }
  return months;
};

const getTradesForMonth = (
  tradeList,
  startOfMonth,
  startOfNextMonth,
  symbol
) => {
  if (symbol) {
    return tradeList.filter(
      (t) =>
        t.openTime >= startOfMonth &&
        t.openTime < startOfNextMonth &&
        t.symbol === symbol
    );
  } else {
    return tradeList.filter(
      (t) => t.openTime >= startOfMonth && t.openTime < startOfNextMonth
    );
  }
};

const getNumberOfDaysInMonth = (date) => {
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  return new Date(year, month + 1, 0).getUTCDate() + 1;
};

const getMonthList = (startTime, endTime, unfilteredTradeList, allSymbols) => {
  const tradeList = unfilteredTradeList.filter((t) => t.marginAllTime !== 0);
  const months = getAllMonthsBetweenTwoDates(
    new Date(startTime + MS_PER_MIN),
    new Date(endTime)
  );

  months.forEach((month, index) => {
    const numberOfDays = getNumberOfDaysInMonth(new Date(month.start));
    const startOfNextMonth = months[index + 1]
      ? months[index + 1].start
      : month.start + MS_PER_DAY * numberOfDays;
    const allTradesOfMonth = getTradesForMonth(
      tradeList,
      month.start,
      startOfNextMonth
    );
    month.trades = allTradesOfMonth;
    month.sum = getSumOfTrades(allTradesOfMonth);
    month.mean = getMeanNetProfitOfTrades(allTradesOfMonth);
    month.stdev = getStandardDeviationOfTrades(allTradesOfMonth);
    month.tradesPerSymbol = [];
    allSymbols.forEach((symbol) => {
      const tradesOfSymbolAndMonth = getTradesForMonth(
        tradeList,
        month.start,
        startOfNextMonth,
        symbol
      );
      month.tradesPerSymbol.push({
        symbol: symbol,
        trades: tradesOfSymbolAndMonth,
        sum: getSumOfTrades(tradesOfSymbolAndMonth),
        mean: getMeanNetProfitOfTrades(tradesOfSymbolAndMonth),
        stdev: getStandardDeviationOfTrades(tradesOfSymbolAndMonth),
      });
    });
  });
  const monthList = {
    months: months,
    sum: getSumOfTrades(tradeList),
    mean: getMeanNetProfitOfTrades(tradeList),
    stdev: getStandardDeviationOfTrades(tradeList),
    max: getMaxOfTrades(tradeList),
    min: getMinOfTrades(tradeList),
    win: getWinRateOfTrades(tradeList),
    sumM: getSumOfMonths(months),
    meanM: getMeanNetProfitOfMonths(months),
    stdevM: getStandardDeviationOfMonths(months),
    maxM: getMaxOfMonths(months),
    minM: getMinOfMonths(months),
    winM: getWinRateOfMonths(months),
    allSymbols: allSymbols.sort(),
    totalResultPerSymbol: [],
  };
  allSymbols.forEach((symbol) => {
    monthList.totalResultPerSymbol.push({
      symbol: symbol,
      mean: getMeanNetProfitOfTrades(
        tradeList.filter((t) => t.symbol === symbol)
      ),
      stdev: getStandardDeviationOfTrades(
        tradeList.filter((t) => t.symbol === symbol)
      ),
    });
  });
  return monthList;
};

export default getMonthList;
