import React from "react";
import styled from "styled-components";
import ResultAreaContainer from "../Components/ResultAreaContainer";
import TenKTable from "./Components/TenKTable";
import TenKChart from "./Components/TenKChart";

const Container = styled(ResultAreaContainer)`
  .MuiTableContainer-root {
    max-height: 500px !important;
  }
  .MuiTableCell-root {
    width: 100px;
  }
`;

const TenKTest = ({ tenKList, historicalData }) => {
  return (
    <Container>
      <TenKChart
        tenKList={tenKList}
        historicalData={historicalData}
      ></TenKChart>
      <TenKTable tenKList={tenKList}></TenKTable>
    </Container>
  );
};

export default TenKTest;
