import { sum, max, mean, std, min, median } from "mathjs";

const MS_PER_MIN = 1000 * 60;
const MS_PER_HOUR = 1000 * 60 * 60;

const flattenArray = (arr) => arr.reduce((a, b) => a.concat(b), []);

const deduplicate = (arr) => {
  const uniqueIds = new Set();
  return arr.filter((obj) => {
    if (uniqueIds.has(obj.id)) return false;
    uniqueIds.add(obj.id);
    return true;
  });
};

const getWeekNumber = (dt) => {
  var tdt = new Date(dt.valueOf());
  var dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  var firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

const splitIntoGroupsOf = (arr, groupSize) =>
  Array.from({ length: Math.ceil(arr.length / groupSize) }, (v, i) =>
    arr.slice(i * groupSize, i * groupSize + groupSize)
  );

const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

const getTenKList = (
  startTime,
  endTime,
  tradeList,
  leverage,
  startingMarginBalance,
  investmentPercentage,
  comission,
  offsetLongShort,
  opposingTradeHandling,
  hardStopLoss,
  maxIpPerSymbol,
  concurrentTrades,
  maxConcurrentTrades
) => {
  // Preparation
  const enhancedTradeList = [...tradeList];
  let currentMarginBalanceAllTime = startingMarginBalance;
  let currentMarginBalanceYearly = startingMarginBalance;
  let currentMarginBalanceMonthly = startingMarginBalance;
  let currentMarginBalanceWeekly = startingMarginBalance;
  let takenProfitWeekly = 0;
  let takenProfitMonthly = 0;
  let takenProfitYearly = 0;

  // empty hours array
  const allRelevantHours = [];
  for (
    let t = startTime + MS_PER_MIN - 1;
    t <= endTime + MS_PER_MIN - 1;
    t = t + MS_PER_HOUR
  ) {
    allRelevantHours.push({
      time: t,
    });
  }

  // fill hours array
  const currentlyOpenPositions = [];
  allRelevantHours.forEach((hour, index) => {
    const openingPositions = enhancedTradeList.filter(
      (t) => t.openTime === hour.time
    );
    const closingPositions = currentlyOpenPositions.filter(
      (t) => t.closeTime <= hour.time
    );
    if (allRelevantHours[index - 1]) {
      if (
        new Date(hour.time).getFullYear() !==
        new Date(allRelevantHours[index - 1].time).getFullYear()
      ) {
        if (currentMarginBalanceYearly > startingMarginBalance) {
          const profitOfYear =
            currentMarginBalanceYearly - startingMarginBalance;
          takenProfitYearly = takenProfitYearly + profitOfYear;
          currentMarginBalanceYearly = startingMarginBalance;
        }
      }
      if (
        new Date(hour.time).getMonth() !==
        new Date(allRelevantHours[index - 1].time).getMonth()
      ) {
        if (currentMarginBalanceMonthly > startingMarginBalance) {
          const profitOfMonth =
            currentMarginBalanceMonthly - startingMarginBalance;
          takenProfitMonthly = takenProfitMonthly + profitOfMonth;
          currentMarginBalanceMonthly = startingMarginBalance;
        }
      }
      if (
        getWeekNumber(new Date(hour.time)) !==
        getWeekNumber(new Date(allRelevantHours[index - 1].time))
      ) {
        if (currentMarginBalanceWeekly > startingMarginBalance) {
          const profitOfWeek =
            currentMarginBalanceWeekly - startingMarginBalance;
          takenProfitWeekly = takenProfitWeekly + profitOfWeek;
          currentMarginBalanceWeekly = startingMarginBalance;
        }
      }
    }

    const opposingPositionsOnSameSymbol = [];
    const opposedPositionsOnSameSymbol = [];
    openingPositions.forEach((openingPosition) => {
      const opposedPositions = currentlyOpenPositions.filter(
        (currentlyOpenPosition) =>
          openingPosition.symbol === currentlyOpenPosition.symbol &&
          openingPosition.type.split("_CONCURRENT")[0] !==
            currentlyOpenPosition.type.split("_CONCURRENT")[0]
      );
      if (opposedPositions.length) {
        opposingPositionsOnSameSymbol.push(openingPosition);
        opposedPositionsOnSameSymbol.push(opposedPositions);
      }
    });
    if (opposingPositionsOnSameSymbol.length) {
      const opposedPositions = deduplicate(
        flattenArray(opposedPositionsOnSameSymbol)
      );
      opposedPositions.forEach((opposedPosition) => {
        if (!opposedPosition.liquidated) {
          const indexInTradeList = enhancedTradeList.findIndex(
            (t) => t.id === opposedPosition.id
          );
          const opposingPosition = opposingPositionsOnSameSymbol.find(
            (opposingPosition) =>
              opposingPosition.symbol === opposedPosition.symbol
          );
          enhancedTradeList[indexInTradeList].opposed = true;
          if (opposingTradeHandling === "CLOSE_AND_OPEN") {
            enhancedTradeList[indexInTradeList].closeTime =
              opposingPosition.openTime;
            enhancedTradeList[indexInTradeList].closePrice =
              opposingPosition.entryPrice;
            enhancedTradeList[indexInTradeList].durationHours =
              (enhancedTradeList[indexInTradeList].closeTime -
                enhancedTradeList[indexInTradeList].openTime) /
              MS_PER_HOUR;
            if (hardStopLoss) {
              enhancedTradeList[indexInTradeList].grossProfit =
                enhancedTradeList[indexInTradeList].type.indexOf(
                  "OPEN_LONG"
                ) === 0
                  ? Math.max(
                      (enhancedTradeList[indexInTradeList].closePrice -
                        enhancedTradeList[indexInTradeList].entryPrice) /
                        enhancedTradeList[indexInTradeList].entryPrice,
                      hardStopLoss
                    )
                  : Math.max(
                      (enhancedTradeList[indexInTradeList].entryPrice -
                        enhancedTradeList[indexInTradeList].closePrice) /
                        enhancedTradeList[indexInTradeList].entryPrice,
                      hardStopLoss
                    );
            } else {
              enhancedTradeList[indexInTradeList].grossProfit =
                enhancedTradeList[indexInTradeList].type.indexOf(
                  "OPEN_LONG"
                ) === 0
                  ? (enhancedTradeList[indexInTradeList].closePrice -
                      enhancedTradeList[indexInTradeList].entryPrice) /
                    enhancedTradeList[indexInTradeList].entryPrice
                  : (enhancedTradeList[indexInTradeList].entryPrice -
                      enhancedTradeList[indexInTradeList].closePrice) /
                    enhancedTradeList[indexInTradeList].entryPrice;
            }
            enhancedTradeList[indexInTradeList].netProfit =
              enhancedTradeList[indexInTradeList].grossProfit - comission;
            const indexInCurrentOpenPositions =
              currentlyOpenPositions.findIndex(
                (t) => t.id === opposedPosition.id
              );
            currentlyOpenPositions[indexInCurrentOpenPositions] = {
              ...enhancedTradeList[indexInTradeList],
              marginMonthly:
                currentlyOpenPositions[indexInCurrentOpenPositions]
                  .marginMonthly,
              marginWeekly:
                currentlyOpenPositions[indexInCurrentOpenPositions]
                  .marginWeekly,
              marginYearly:
                currentlyOpenPositions[indexInCurrentOpenPositions]
                  .marginYearly,
            };
          }
        }
      });
      opposingPositionsOnSameSymbol.forEach((opposingPosition) => {
        const indexInTradeList = enhancedTradeList.findIndex(
          (trade) => trade.id === opposingPosition.id
        );
        const indexInOpeningPositions = openingPositions.findIndex(
          (openingPosition) => openingPosition.id === opposingPosition.id
        );
        enhancedTradeList[indexInTradeList].opposing = true;
        openingPositions[indexInOpeningPositions].opposing = true;
      });
    }

    closingPositions.forEach((closingPosition) => {
      currentlyOpenPositions.splice(
        currentlyOpenPositions.findIndex((p) => p.id === closingPosition.id),
        1
      );
      const profitAllTime =
        closingPosition.marginAllTime * closingPosition.netProfit;
      const profitYearly =
        closingPosition.marginYearly * closingPosition.netProfit;
      const profitMonthly =
        closingPosition.marginMonthly * closingPosition.netProfit;
      const profitWeekly =
        closingPosition.marginWeekly * closingPosition.netProfit;
      currentMarginBalanceAllTime = currentMarginBalanceAllTime + profitAllTime;
      currentMarginBalanceYearly = currentMarginBalanceYearly + profitYearly;
      currentMarginBalanceMonthly = currentMarginBalanceMonthly + profitMonthly;
      currentMarginBalanceWeekly = currentMarginBalanceWeekly + profitWeekly;
      if (currentMarginBalanceWeekly < 0) {
        takenProfitWeekly = takenProfitWeekly - currentMarginBalanceWeekly;
        currentMarginBalanceWeekly = startingMarginBalance;
      }
      if (currentMarginBalanceMonthly < 0) {
        takenProfitMonthly = takenProfitMonthly - currentMarginBalanceMonthly;
        currentMarginBalanceMonthly = startingMarginBalance;
      }
      if (currentMarginBalanceYearly < 0) {
        takenProfitYearly = takenProfitYearly - currentMarginBalanceYearly;
        currentMarginBalanceYearly = startingMarginBalance;
      }
    });
    openingPositions
      .sort((a, b) => a.symbol.localeCompare(b.symbol))
      .forEach((openingPosition) => {
        let marginAllTimeOfOpenContraryPositionsOnTheSameSymbol = 0;
        let marginYearlyOfOpenContraryPositionsOnTheSameSymbol = 0;
        let marginMonthlyOfOpenContraryPositionsOnTheSameSymbol = 0;
        let marginWeeklyOfOpenContraryPositionsOnTheSameSymbol = 0;
        if (offsetLongShort) {
          marginAllTimeOfOpenContraryPositionsOnTheSameSymbol =
            currentlyOpenPositions.reduce((totalMargin, existingPosition) => {
              if (
                existingPosition.symbol === openingPosition.symbol &&
                existingPosition.type !== openingPosition.type
              ) {
                return totalMargin + existingPosition.marginAllTime;
              } else {
                return totalMargin;
              }
            }, 0);
          marginYearlyOfOpenContraryPositionsOnTheSameSymbol =
            currentlyOpenPositions.reduce((totalMargin, existingPosition) => {
              if (
                existingPosition.symbol === openingPosition.symbol &&
                existingPosition.type !== openingPosition.type
              ) {
                return totalMargin + existingPosition.marginYearly;
              } else {
                return totalMargin;
              }
            }, 0);
          marginMonthlyOfOpenContraryPositionsOnTheSameSymbol =
            currentlyOpenPositions.reduce((totalMargin, existingPosition) => {
              if (
                existingPosition.symbol === openingPosition.symbol &&
                existingPosition.type !== openingPosition.type
              ) {
                return totalMargin + existingPosition.marginMonthly;
              } else {
                return totalMargin;
              }
            }, 0);
          marginWeeklyOfOpenContraryPositionsOnTheSameSymbol =
            currentlyOpenPositions.reduce((totalMargin, existingPosition) => {
              if (
                existingPosition.symbol === openingPosition.symbol &&
                existingPosition.type !== openingPosition.type
              ) {
                return totalMargin + existingPosition.marginWeekly;
              } else {
                return totalMargin;
              }
            }, 0);
        }
        const currentlyOpenMarginSumAllTime = sum(
          currentlyOpenPositions.map((p) => p.marginAllTime)
        );
        const currentlyOpenMarginSumYearly = sum(
          currentlyOpenPositions.map((p) => p.marginYearly)
        );
        const currentlyOpenMarginSumMonthly = sum(
          currentlyOpenPositions.map((p) => p.marginMonthly)
        );
        const currentlyOpenMarginSumWeekly = sum(
          currentlyOpenPositions.map((p) => p.marginWeekly)
        );
        let availableMarginAllTime =
          currentMarginBalanceAllTime * leverage -
          currentlyOpenMarginSumAllTime;
        let availableMarginYearly =
          currentMarginBalanceAllTime * leverage - currentlyOpenMarginSumYearly;
        let availableMarginMonthly =
          currentMarginBalanceAllTime * leverage -
          currentlyOpenMarginSumMonthly;
        let availableMarginWeekly =
          currentMarginBalanceAllTime * leverage - currentlyOpenMarginSumWeekly;

        let marginAllTime =
          availableMarginAllTime >=
          currentMarginBalanceAllTime * investmentPercentage * leverage -
            marginAllTimeOfOpenContraryPositionsOnTheSameSymbol
            ? currentMarginBalanceAllTime * investmentPercentage * leverage
            : 0;
        let marginYearly =
          availableMarginYearly >=
          currentMarginBalanceYearly * investmentPercentage * leverage -
            marginYearlyOfOpenContraryPositionsOnTheSameSymbol
            ? currentMarginBalanceYearly * investmentPercentage * leverage
            : 0;
        let marginMonthly =
          availableMarginMonthly >=
          currentMarginBalanceMonthly * investmentPercentage * leverage -
            marginMonthlyOfOpenContraryPositionsOnTheSameSymbol
            ? currentMarginBalanceMonthly * investmentPercentage * leverage
            : 0;
        let marginWeekly =
          availableMarginWeekly >=
          currentMarginBalanceWeekly * investmentPercentage * leverage -
            marginWeeklyOfOpenContraryPositionsOnTheSameSymbol
            ? currentMarginBalanceWeekly * investmentPercentage * leverage
            : 0;
        if (currentlyOpenPositions.length > 0) {
          const symbol = openingPosition.symbol;
          const filteredOpenPositions = currentlyOpenPositions.filter(
            (t) => t.symbol === symbol
          );
          if (filteredOpenPositions.length) {
            if (maxIpPerSymbol) {
              const filteredMarginsAllTime = filteredOpenPositions.map(
                (p) => p.marginAllTime
              );
              const filteredMarginsYearly = filteredOpenPositions.map(
                (p) => p.marginYearly
              );
              const filteredMarginsMonthly = filteredOpenPositions.map(
                (p) => p.marginMonthly
              );
              const filteredMarginsWeekly = filteredOpenPositions.map(
                (p) => p.marginWeekly
              );
              const currentMarginForSymbolAllTime = sum(filteredMarginsAllTime);
              const currentMarginForSymbolYearly = sum(filteredMarginsYearly);
              const currentMarginForSymbolMonthly = sum(filteredMarginsMonthly);
              const currentMarginForSymbolWeekly = sum(filteredMarginsWeekly);
              if (
                currentMarginForSymbolAllTime >=
                currentMarginBalanceAllTime * maxIpPerSymbol
              ) {
                marginAllTime = 0;
              }
              if (
                currentMarginForSymbolYearly >=
                currentMarginBalanceYearly * maxIpPerSymbol
              ) {
                marginYearly = 0;
              }
              if (
                currentMarginForSymbolMonthly >=
                currentMarginBalanceMonthly * maxIpPerSymbol
              ) {
                marginMonthly = 0;
              }
              if (
                currentMarginForSymbolWeekly >=
                currentMarginBalanceWeekly * maxIpPerSymbol
              ) {
                marginWeekly = 0;
              }
            }
            if (concurrentTrades && maxConcurrentTrades !== "UNLIMITED") {
              const currentlyOpenConcurrentPositions =
                filteredOpenPositions.filter(
                  (p) => p.type.indexOf("_CONCURRENT") > 0
                );
              if (
                currentlyOpenConcurrentPositions.length >= maxConcurrentTrades
              ) {
                marginAllTime = 0;
                marginYearly = 0;
                marginMonthly = 0;
                marginWeekly = 0;
              }
            }
          }
        }
        openingPosition.marginAllTime = marginAllTime;
        currentlyOpenPositions.push({
          ...openingPosition,
          marginAllTime: marginAllTime,
          marginYearly: marginYearly,
          marginMonthly: marginMonthly,
          marginWeekly: marginWeekly,
        });
      });
    hour.currentOpenPositions = currentlyOpenPositions.filter(
      (p) => p.marginAllTime > 0
    ).length;
    hour.currentOpenPositionsSize =
      Math.round(
        (sum(currentlyOpenPositions.map((p) => p.marginAllTime)) /
          currentMarginBalanceAllTime) *
          10
      ) / 10;
    hour.currentMarginBalanceWeekly = currentMarginBalanceWeekly;
    hour.currentMarginBalanceMonthly = currentMarginBalanceMonthly;
    hour.currentMarginBalanceYearly = currentMarginBalanceYearly;
    hour.currentMarginBalanceAllTime = currentMarginBalanceAllTime;
  });

  // empty days array
  const allRelevantDays = [];
  allRelevantHours.forEach((hour) => {
    if (
      allRelevantDays[allRelevantDays.length - 1] &&
      allRelevantDays[allRelevantDays.length - 1].date ===
        new Date(hour.time).toISOString().split("T")[0]
    )
      return;
    allRelevantDays.push({
      time: hour.time,
      date: new Date(hour.time).toISOString().split("T")[0],
    });
  });

  // prepare days array
  const hoursForDays = splitIntoGroupsOf(allRelevantHours, 24);
  allRelevantDays.forEach((day, index) => {
    const relevantHours = hoursForDays[index];
    if (relevantHours) {
      const lastRelevantHour = relevantHours[relevantHours.length - 1];
      allRelevantDays[index].balanceAllTime =
        lastRelevantHour.currentMarginBalanceAllTime;
      allRelevantDays[index].balanceYearly =
        lastRelevantHour.currentMarginBalanceYearly;
      allRelevantDays[index].balanceMonthly =
        lastRelevantHour.currentMarginBalanceMonthly;
      allRelevantDays[index].balanceWeekly =
        lastRelevantHour.currentMarginBalanceWeekly;
      allRelevantDays[index].maxOpenPositions = max(
        relevantHours.map((h) => h.currentOpenPositions)
      );
      allRelevantDays[index].lastOpenPositions =
        lastRelevantHour.currentOpenPositions;
    }
  });

  // simultanityAnalysis
  const currentOpenPositions = allRelevantHours.map(
    (h) => h.currentOpenPositions
  );
  const currentOpenPositionsSize = allRelevantHours.map(
    (h) => h.currentOpenPositionsSize / leverage
  );
  const missedTrades = enhancedTradeList.filter(
    (t) => t.marginAllTime === 0
  ).length;
  const simultanityResults = {
    mean: mean(currentOpenPositions),
    sdev: std(currentOpenPositions),
    min: min(currentOpenPositions),
    max: max(currentOpenPositions),
    median: median(currentOpenPositions),
    missed: missedTrades,
    cutoff: (missedTrades / enhancedTradeList.length) * 100,
    meanUtil: mean(currentOpenPositionsSize),
    sdevUtil: std(currentOpenPositionsSize),
    minUtil: min(currentOpenPositionsSize),
    maxUtil: max(currentOpenPositionsSize),
    medianUtil: median(currentOpenPositionsSize),
    possibleUtil: 1,
    simList: [],
  };
  const simultanityGroups = groupBy(allRelevantHours, "currentOpenPositions");
  for (const [key, value] of Object.entries(simultanityGroups)) {
    simultanityResults[`simList`].push({ l: key, value: value.length });
  }

  return {
    days: allRelevantDays,
    takenProfitWeekly,
    takenProfitMonthly,
    takenProfitYearly,
    startingMarginBalance,
    enhancedTradeList: enhancedTradeList,
    simultanityResults,
  };
};

export default getTenKList;
