import React from "react";
import formatAmount from "../../../../../../../Common/Functions/formatAmount";
import formatPercentage from "../../../../../../../Common/Functions/formatPercentage";
import Column from "../../../Components/SummaryComponents/Column";
import ColumnHeader from "../../../Components/SummaryComponents/ColumnHeader";
import ColumnBody from "../../../Components/SummaryComponents/ColumnBody";
import ColumnBodyLineGroup from "../../../Components/SummaryComponents/ColumnBodyLineGroup";
import ColumnBodyLine from "../../../Components/SummaryComponents/ColumnBodyLine";
import LabelValuePair from "../../../Components/SummaryComponents/LabelValuePair";
import Label from "../../../Components/SummaryComponents/Label";
import Value from "../../../Components/SummaryComponents/Value";
import styled from "styled-components";

const WideValue = styled(Value)`
  width: 120px;
`;

const TradeCommercialResults = ({ trade }) => {
  return (
    <Column>
      <ColumnHeader>Commercials</ColumnHeader>
      <ColumnBody>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Entry Price</Label>
              <WideValue>{formatAmount(trade.entryPrice, 4)} USDT</WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Close Price</Label>
              <WideValue>{formatAmount(trade.closePrice, 4)} USDT</WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Margin</Label>
              <WideValue>{formatAmount(trade.marginAllTime, 2)} USDT</WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Absolute Profit</Label>
              <WideValue>
                {formatAmount(trade.netProfit * trade.marginAllTime, 2)} USDT
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Gross Profit</Label>
              <WideValue>{formatPercentage(trade.grossProfit)}</WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Net Profit</Label>
              <WideValue>{formatPercentage(trade.netProfit)}</WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
        </ColumnBodyLineGroup>
      </ColumnBody>
    </Column>
  );
};

export default TradeCommercialResults;
