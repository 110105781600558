import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import formatAmount from "../../../../Common/Functions/formatAmount";
import formatPercentage from "../../../../Common/Functions/formatPercentage";
import CodeContainer from "./CodeContainer";

const Container = styled.div`
  .MuiTableContainer-root {
    .MuiTableRow-root {
      vertical-align: baseline;
    }
    .MuiTableCell-stickyHeader {
      background-color: #072130;
    }
  }
  .MuiFormControl-root {
    width: 300px;
    margin: 0 12px 0 0;
  }
`;

const DetailContainer = ({
  shownCombinerResult,
  getConfigFromId,
  definitions,
}) => {
  const [symbolFilter, setSymbolFilter] = useState("NONE");
  const [triggerFilter, setTriggerFilter] = useState("NONE");
  const [directionFilter, setDirectionFilter] = useState("NONE");
  const [allSymbolFilters, setAllSymbolFilters] = useState(["NONE"]);
  const [allTriggerFilters, setAllTriggerFilters] = useState(["NONE"]);
  const [allDirectionFilters, setAllDirectionFilters] = useState(["NONE"]);
  const [shownStats, setShownStats] = useState(shownCombinerResult.finalStats);

  useEffect(() => {
    const symbolFilters = ["NONE"];
    shownCombinerResult.finalStats.forEach((s) => {
      symbolFilters.push(s.symbol);
    });
    setAllSymbolFilters([...new Set(symbolFilters)]);
    const triggerFilters = ["NONE"];
    shownCombinerResult.finalStats.forEach((s) => {
      triggerFilters.push(s.trigger);
    });
    setAllTriggerFilters([...new Set(triggerFilters)]);
    const directionFilters = ["NONE"];
    shownCombinerResult.finalStats.forEach((s) => {
      directionFilters.push(s.direction);
    });
    setAllDirectionFilters([...new Set(directionFilters)]);
  }, [shownCombinerResult]);

  useEffect(() => {
    let stats = [...shownCombinerResult.finalStats];
    if (symbolFilter !== "NONE") {
      stats = [...stats.filter((s) => s.symbol === symbolFilter)];
    }
    if (triggerFilter !== "NONE") {
      stats = [...stats.filter((s) => s.trigger === triggerFilter)];
    }
    if (directionFilter !== "NONE") {
      stats = [...stats.filter((s) => s.direction === directionFilter)];
    }
    setShownStats(stats);
  }, [symbolFilter, triggerFilter, directionFilter, shownCombinerResult]);

  return (
    <Container>
      <h2>Details of Combination</h2>
      <h3>Final Statistics</h3>
      <div>{JSON.stringify(shownCombinerResult.parameterCombination)}</div>
      <h3>Final Statistics</h3>
      <FormControl>
        <InputLabel id="selected-symbol-label">Symbol Filter</InputLabel>
        <Select
          color="secondary"
          labelId="selected-symbol-label"
          value={symbolFilter}
          onChange={(e) => setSymbolFilter(e.target.value)}
        >
          {allSymbolFilters.map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="selected-trigger-label">Trigger Filter</InputLabel>
        <Select
          color="secondary"
          labelId="selected-trigger-label"
          value={triggerFilter}
          onChange={(e) => setTriggerFilter(e.target.value)}
        >
          {allTriggerFilters.map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="selected-direction-label">Direction Filter</InputLabel>
        <Select
          color="secondary"
          labelId="selected-direction-label"
          value={directionFilter}
          onChange={(e) => setDirectionFilter(e.target.value)}
        >
          {allDirectionFilters.map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Symbol</TableCell>
              <TableCell>Trigger</TableCell>
              <TableCell>Direction</TableCell>
              <TableCell>Percent of Trades</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shownStats
              .filter((stat) => stat.shareOfTotalTrades)
              .sort((a, b) => b.shareOfTotalTrades - a.shareOfTotalTrades)
              .map((stat, index) => (
                <TableRow key={index}>
                  <TableCell scope="row">{stat.symbol}</TableCell>
                  <TableCell scope="row">{stat.trigger}</TableCell>
                  <TableCell scope="row">{stat.direction}</TableCell>
                  <TableCell scope="row">
                    {formatPercentage(stat.shareOfTotalTrades)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Optimization Steps</h3>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>STEP</TableCell>
              <TableCell>Added Perfect Partner</TableCell>
              <TableCell align="right">IP</TableCell>
              <TableCell align="right">HighestTenK</TableCell>
              <TableCell align="right">LatestTenK</TableCell>
              <TableCell align="right">Cap Util</TableCell>
              <TableCell align="right">Cutoff</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shownCombinerResult.optimizationSteps.map((step, index) => (
              <TableRow key={index}>
                <TableCell scope="row">{step.iteration}</TableCell>
                <TableCell scope="row">{step.step}</TableCell>
                <TableCell scope="row">{step.perfectPartner}</TableCell>
                <TableCell scope="row" align="right">
                  {step.investmentPercentage}
                </TableCell>
                <TableCell scope="row" align="right">
                  {formatAmount(step.highestTenKAllTimeBalance, 2)}
                </TableCell>
                <TableCell scope="row" align="right">
                  {formatAmount(step.latestTenKAllTimeBalance, 2)}
                </TableCell>
                <TableCell scope="row" align="right">
                  {formatPercentage(step.meanCapitalUtil)}
                </TableCell>
                <TableCell scope="row" align="right">
                  {step.cutoff} %
                </TableCell>
                <TableCell scope="row" align="right">
                  {step.perfectPartner && (
                    <CodeContainer
                      optimizationSteps={shownCombinerResult.optimizationSteps.slice(
                        0,
                        index + 1
                      )}
                      getConfigFromId={getConfigFromId}
                      definitions={definitions}
                    ></CodeContainer>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DetailContainer;
