import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin: 12px;
  padding: 12px;
  background-color: ${({ decisions }) =>
    decisions.length > 0 ? "#061b27" : "#1b3342"};
  border-radius: 4px;
`;

const AnalysisListItem = ({ decisions, children }) => {
  return <Container decisions={decisions}>{children}</Container>;
};

export default AnalysisListItem;
