import React, { useEffect, useState } from "react";
import axios from "axios";

import ControllingTradeOverview from "./Components/ControllingTradeOverview";
import EquityLog from "./Components/EquityLog";

const ControllingModule = () => {
  const [loadingTradeList, setLoadingTradeList] = useState(true);
  const [tradeList, setTradeList] = useState([]);
  const [equityLog, setEquityLog] = useState([]);

  const loadTradeList = async () => {
    setLoadingTradeList(true);
    const { data: result } = await axios.get(
      `trading-manager-kucoin/controlling-dashboard`
    );
    setTradeList(result.resultList);
    setEquityLog(result.equityLog);
    setLoadingTradeList(false);
  };

  useEffect(() => {
    loadTradeList();
  }, []);

  return (
    <div>
      <ControllingTradeOverview
        loadTradeList={loadTradeList}
        loadingTradeList={loadingTradeList}
        tradeList={tradeList}
      ></ControllingTradeOverview>
      <EquityLog equityLog={equityLog}></EquityLog>
    </div>
  );
};

export default ControllingModule;
