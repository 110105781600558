import React from "react";

const formatPercentage = (value) => {
  const colorClass =
    value > 0.05
      ? "dagr"
      : value > 0
      ? "ligr"
      : value < -0.05
      ? "dare"
      : value < 0
      ? "lire"
      : "neutral";
  const percentageNumber = (Math.round(value * 10000) / 100).toLocaleString(
    undefined,
    { minimumFractionDigits: 2 }
  );
  const formattedValue = `${value >= 0 ? "+" : ""}${percentageNumber} %`;
  return <span className={colorClass}>{formattedValue}</span>;
};

export default formatPercentage;
