import React from "react";
import formatPositionType from "../../../../../../../Common/Functions/formatPositionType";
import Column from "../../../Components/SummaryComponents/Column";
import ColumnHeader from "../../../Components/SummaryComponents/ColumnHeader";
import ColumnBody from "../../../Components/SummaryComponents/ColumnBody";
import ColumnBodyLineGroup from "../../../Components/SummaryComponents/ColumnBodyLineGroup";
import ColumnBodyLine from "../../../Components/SummaryComponents/ColumnBodyLine";
import LabelValuePair from "../../../Components/SummaryComponents/LabelValuePair";
import shortenFullIdentifier from "../../../../../../../Common/Functions/shortenFullIdentifier";
import Label from "../../../Components/SummaryComponents/Label";
import Value from "../../../Components/SummaryComponents/Value";
import styled from "styled-components";

const WideValue = styled(Value)`
  width: 300px;
`;

const TradeStrategy = ({ trade, opposingTradeHandling }) => {
  return (
    <Column>
      <ColumnHeader>Strategy</ColumnHeader>
      <ColumnBody>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Strategy ID </Label>
              <Value>{trade.strategy.id.value}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Symbol </Label>
              <Value>{shortenFullIdentifier(trade.symbol)}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Direction </Label>
              <Value>{formatPositionType(trade.type)}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
        </ColumnBodyLineGroup>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Trigger </Label>
              <WideValue>{trade.strategy.trigger.value}</WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
          {trade.strategy.triggerConfigs.map((config) => (
            <ColumnBodyLine key={config.option}>
              <LabelValuePair>
                <Label>{config.option} </Label>
                <Value>{config.value}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          ))}
        </ColumnBodyLineGroup>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Support </Label>
              <Value>{trade.strategy.support.value}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
          {trade.strategy.supportConfigs.map((config) => (
            <ColumnBodyLine key={config.option}>
              <LabelValuePair>
                <Label>{config.option} </Label>
                <Value>{config.value}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          ))}
        </ColumnBodyLineGroup>
        <ColumnBodyLineGroup>
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Close </Label>
              <Value>{trade.strategy.close.value}</Value>
            </LabelValuePair>
          </ColumnBodyLine>
          {trade.strategy.closeConfigs.map((config) => (
            <ColumnBodyLine key={config.option}>
              <LabelValuePair>
                <Label>{config.option} </Label>
                <Value>{config.value}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          ))}
          <ColumnBodyLine>
            <LabelValuePair>
              <Label>Real</Label>
              <WideValue>
                {opposingTradeHandling === "CLOSE_AND_OPEN" && trade.opposed
                  ? "OPPOSED_BY_ANOTHER_TRADE"
                  : trade.closeReason}
              </WideValue>
            </LabelValuePair>
          </ColumnBodyLine>
        </ColumnBodyLineGroup>
      </ColumnBody>
    </Column>
  );
};

export default TradeStrategy;

/* 
      <p>
        Close reason:{" "}
        {opposingTradeHandling === "CLOSE_AND_OPEN" && trade.opposed
          ? "OPPOSED_BY_ANOTHER_TRADE"
          : trade.closeReason}
      </p> */
