import React from "react";
import Editor from "@monaco-editor/react";
import styled from "styled-components";

const EditorContainer = styled.div`
  border: solid 1px #333;
`;

const CodeEditor = ({
  wordWrapColumn,
  defaultValue,
  handleEditorChange,
  readOnly,
  height,
}) => {
  return (
    <EditorContainer>
      {defaultValue && (
        <Editor
          theme="vs-dark"
          height={height}
          defaultLanguage="javascript"
          value={defaultValue}
          onChange={handleEditorChange}
          options={{
            fontSize: "12px",
            minimap: { enabled: false },
            wordWrap: "wordWrapColumn",
            wordWrapColumn: wordWrapColumn,
            readOnly: readOnly,
          }}
        />
      )}
    </EditorContainer>
  );
};

export default CodeEditor;
