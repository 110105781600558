import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Controls from "./Controls/Controls";
import getAllSymbols from "./Functions/getAllSymbols";
import getMonthList from "./Functions/getMonthList";
import getSummary from "./Functions/getSummary";
import getTenKList from "./Functions/getTenKList";
import getTradeList from "./Functions/getTradeList";
import MonthlyPerformance from "./MonthlyPerformance/MonthlyPerformance";
import SimultanityAnalysis from "./SimultanityAnalysis/SimultanityAnalysis";
import Summary from "./Summary/Summary";
import TenKTest from "./TenKTest/TenKTest";
import TradeList from "./TradeList/TradeList";
import constants from "./Constants/constants";

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .MuiPaper-root {
    min-width: 95vw;
    min-height: 95vh;
    background-color: #1b3342;
  }
  .MuiTableContainer-root {
    background-color: #061b27;
    border-radius: 4px;
    max-height: 900px;
    .sum-area {
      background-color: #00000033;
      font-weight: bold;
    }
    .isFirst {
      background-color: #627c8c73;
    }
    .MuiTableCell-root {
      padding: 6px 9px;
      font-size: 12px;
    }
  }
  .dagr {
    color: #51d60e;
  }
  .ligr {
    color: #abef89;
  }
  .dare {
    color: #ef4d1d;
  }
  .lire {
    color: #ff7e58;
  }
  .neutral {
    color: #e4e4e4;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 2000px;
`;
const SummaryLine = styled.div`
  display: flex;
  flex-direction: row;
`;
const ResultColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const getCombinationCounts = (trades) => {
  const counts = {};
  const totalCount = trades.length;
  let allLongCount = 0;
  let allShortCount = 0;
  let resultString = "";

  for (let trade of trades) {
    const { type, symbol } = trade;

    const key = `${type.replace("_CONCURRENT", "")}-${symbol}`;
    counts[key] = (counts[key] || 0) + 1;

    if (type.indexOf("OPEN_LONG") === 0) {
      allLongCount++;
    } else if (type.indexOf("OPEN_SHORT") === 0) {
      allShortCount++;
    }
  }

  // Calculate percentages and generate result string
  for (let key in counts) {
    const count = counts[key];
    const percentage = ((count / totalCount) * 100).toFixed(2);
    resultString += `${key}: ${count} (${percentage}%) \n`;
  }

  // Add total counts to the result string
  resultString += `\nTotal Trades: ${totalCount}\n`;
  resultString += `All Long Trades: ${allLongCount} (${(
    (allLongCount / totalCount) *
    100
  ).toFixed(2)}%)\n`;
  resultString += `All Short Trades: ${allShortCount} (${(
    (allShortCount / totalCount) *
    100
  ).toFixed(2)}%)\n`;

  // Calculate counts and percentages by symbol
  const symbolsCounts = {};
  for (let trade of trades) {
    const { symbol } = trade;
    symbolsCounts[symbol] = (symbolsCounts[symbol] || 0) + 1;
  }

  // Add counts and percentages by symbol to the result string
  resultString += `\nCounts by Symbol:\n`;
  for (let symbol in symbolsCounts) {
    const count = symbolsCounts[symbol];
    const percentage = ((count / totalCount) * 100).toFixed(2);
    resultString += `${symbol}: ${count} (${percentage}%) \n`;
  }

  return resultString.trim();
};

const BacktestResultDialog = ({
  loadingSingleBacktestResult,
  shownBacktestResults,
  setShownBacktestResults,
  currentlyViewedStrategy,
  historicalData,
}) => {
  const [tradeList, setTradeList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [summary, setSummary] = useState([]);
  const [tenKList, setTenKList] = useState([]);
  const [simultanityData, setSimultanityData] = useState({});
  const [leverage, setLeverage] = useState(1);
  const [comission, setComission] = useState(0.0015);
  const [startingMarginBalance, setStartingMarginBalance] = useState(10000);
  const [investmentPercentage, setInvestmentPercentage] = useState(0.03);
  const [concurrentTrades, setConcurrentTrades] = useState(true);
  const [offsetLongShort, setOffsetLongShort] = useState(true);
  const [maxIpPerSymbol, setMaxIpPerSymbol] = useState(1);
  const [maxConcurrentTrades, setMaxConcurrentTrades] = useState("UNLIMITED");
  const [opposingTradeHandling, setOpposingTradeHandling] =
    useState("CLOSE_AND_OPEN");

  const handleClose = () => {
    setShownBacktestResults([]);
  };

  useEffect(() => {
    if (!shownBacktestResults.results?.length) return;
    if (!shownBacktestResults.results?.length || !historicalData.length) return;
    const allSymbols = getAllSymbols(shownBacktestResults.results);
    const startTime = new Date(
      shownBacktestResults.startTime - constants.MS_PER_DAY
    ).setUTCMinutes(59);
    const endTime = new Date(
      shownBacktestResults.endTime + constants.MS_PER_DAY
    ).setUTCMinutes(59);
    const strategies = currentlyViewedStrategy?.strategy?.id
      ? JSON.parse(
          currentlyViewedStrategy.code.config.replace("module.exports = ", "")
        )
      : JSON.parse(
          shownBacktestResults.config.config.replace("module.exports = ", "")
        );
    const tradeListResult = getTradeList(
      shownBacktestResults.results,
      comission,
      concurrentTrades,
      strategies,
      leverage,
      false
    );
    const tenKListResult = getTenKList(
      startTime,
      endTime,
      tradeListResult,
      leverage,
      startingMarginBalance,
      investmentPercentage,
      comission,
      offsetLongShort,
      opposingTradeHandling,
      shownBacktestResults.hardStopLoss,
      maxIpPerSymbol,
      concurrentTrades,
      maxConcurrentTrades
    );
    const monthListResult = getMonthList(
      startTime,
      endTime,
      tenKListResult.enhancedTradeList,
      allSymbols
    );
    const summaryResult = getSummary(monthListResult);
    const combinationCounts = getCombinationCounts(
      tenKListResult.enhancedTradeList.filter((t) => t.marginAllTime > 0)
    );
    console.log(combinationCounts);
    setTradeList(tenKListResult.enhancedTradeList);
    setSimultanityData(tenKListResult.simultanityResults);
    setMonthList(monthListResult);
    setSummary(summaryResult);
    setTenKList(tenKListResult);
  }, [
    shownBacktestResults,
    leverage,
    currentlyViewedStrategy,
    comission,
    startingMarginBalance,
    concurrentTrades,
    investmentPercentage,
    offsetLongShort,
    opposingTradeHandling,
    historicalData,
    maxIpPerSymbol,
    maxConcurrentTrades,
  ]);

  return (
    <StyledDialog
      maxWidth="xl"
      open={
        loadingSingleBacktestResult || shownBacktestResults.results?.length > 0
      }
      onClose={handleClose}
      disableBackdropClick
    >
      {currentlyViewedStrategy && (
        <>
          <DialogTitle>
            <span>
              Backtest Results of Strategy:{" "}
              {currentlyViewedStrategy.strategy.name ?? "New Strategy"}
            </span>
            <IconButton onClick={handleClose}>
              <Close></Close>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {loadingSingleBacktestResult ? (
              <LinearProgress color="secondary"></LinearProgress>
            ) : (
              <Container>
                <InnerContainer>
                  <SummaryLine>
                    <Summary summary={summary}></Summary>
                    <SimultanityAnalysis
                      simultanityData={simultanityData}
                    ></SimultanityAnalysis>
                  </SummaryLine>
                  <ResultColumnsContainer>
                    <MonthlyPerformance
                      monthList={monthList}
                    ></MonthlyPerformance>
                    <TenKTest
                      tenKList={tenKList}
                      historicalData={historicalData}
                    ></TenKTest>
                    <TradeList
                      startTime={shownBacktestResults.startTime}
                      endTime={shownBacktestResults.endTime}
                      tradeList={tradeList}
                      opposingTradeHandling={opposingTradeHandling}
                      strategyId={
                        currentlyViewedStrategy.strategy.id || "_EPHEMERAL"
                      }
                    ></TradeList>
                  </ResultColumnsContainer>
                </InnerContainer>
                <Controls
                  leverage={leverage}
                  setLeverage={(v) => setLeverage(v)}
                  comission={comission}
                  setComission={(v) => setComission(v)}
                  startingMarginBalance={startingMarginBalance}
                  setStartingMarginBalance={(v) => setStartingMarginBalance(v)}
                  concurrentTrades={concurrentTrades}
                  setConcurrentTrades={(v) => setConcurrentTrades(v)}
                  investmentPercentage={investmentPercentage}
                  setInvestmentPercentage={(v) => setInvestmentPercentage(v)}
                  offsetLongShort={offsetLongShort}
                  setOffsetLongShort={(v) => setOffsetLongShort(v)}
                  opposingTradeHandling={opposingTradeHandling}
                  setOpposingTradeHandling={(v) => setOpposingTradeHandling(v)}
                  hardStopLoss={shownBacktestResults.hardStopLoss}
                  hardStopDuration={shownBacktestResults.hardStopDuration}
                  maxIpPerSymbol={maxIpPerSymbol}
                  setMaxIpPerSymbol={setMaxIpPerSymbol}
                  maxConcurrentTrades={maxConcurrentTrades}
                  setMaxConcurrentTrades={setMaxConcurrentTrades}
                  trendCheckForTrades={shownBacktestResults.trendCheckForTrades}
                ></Controls>
              </Container>
            )}
          </DialogContent>
        </>
      )}
    </StyledDialog>
  );
};

export default BacktestResultDialog;
