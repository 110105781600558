import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  .MuiTableCell-root {
    padding: 3px;
    text-align: right;
    width: 130px;
  }
  .MuiTableCell-root.time {
    width: 70px;
  }
`;

const AnalysisListItemCandle = ({ label, candle }) => {
  const formatTime = (time) =>
    `${new Date(time).getUTCHours().toString().padStart(2, "0")}:${new Date(
      time
    )
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}`;

  const formatAmount = (amount, digits = 4) =>
    amount.toLocaleString("de-DE", {
      minimumFractionDigits: digits,
    });

  return (
    <Container>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="time">
                {formatTime(candle.openTime)}
              </TableCell>
              <TableCell className="time">
                {formatTime(candle.closeTime)}
              </TableCell>
              <TableCell>{formatAmount(candle.open)}</TableCell>
              <TableCell>{formatAmount(candle.high)}</TableCell>
              <TableCell>{formatAmount(candle.low)}</TableCell>
              <TableCell>{formatAmount(candle.close)}</TableCell>
              <TableCell>{formatAmount(candle.volume, 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AnalysisListItemCandle;
