import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { IconButton, LinearProgress } from "@material-ui/core";
import { Launch, Delete, Refresh, FileCopy } from "@material-ui/icons";

const Container = styled.div`
  margin: 12px;
  width: 180px;
`;

const StrategyList = styled.div``;

const StrategyItem = styled.div`
  background-color: #345061;
  padding: 6px;
  border-radius: 4px;
  margin: 6px 0;
`;
const StrategyName = styled.h3`
  margin: 0;
  font-size: 14px;
`;

const ReloadButtonAndProgressContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  margin-bottom: 12px;
  button {
    position: absolute;
    top: 3px;
    right: 12px;
  }
  .MuiLinearProgress-root {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
`;
const StrategyDescription = styled.p`
  margin: 0;
  font-size: 11px;
`;

const ButtonContainer = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
`;

const ExistingStrategiesList = ({
  viewStrategy,
  deleteStrategy,
  copyStrategy,
}) => {
  const [existingStrategies, setExistingStrategies] = useState([]);
  const [loadingStrategies, setLoadingStrategies] = useState(false);

  const loadExistingStrategies = async () => {
    setLoadingStrategies(true);
    const { data: strategies } = await axios.get(
      `strategy-manager/strategy-list`
    );
    setExistingStrategies(strategies);
    setLoadingStrategies(false);
  };

  useEffect(() => {
    loadExistingStrategies();
  }, []);

  return (
    <Container>
      <h2>Strategies</h2>
      <StrategyList>
        <StrategyItem>
          <StrategyName>New Strategy</StrategyName>
          <StrategyDescription>Create new Strategy</StrategyDescription>
          <ButtonContainer>
            <IconButton size="small" onClick={() => viewStrategy({ id: null })}>
              <Launch fontSize="inherit"></Launch>
            </IconButton>
          </ButtonContainer>
        </StrategyItem>
        <br></br>
        <hr></hr>
        <Scrollbars style={{ height: "77vh" }}>
          <ReloadButtonAndProgressContainer>
            <IconButton size="small" onClick={() => loadExistingStrategies()}>
              <Refresh fontSize="inherit"></Refresh>
            </IconButton>
            {loadingStrategies && (
              <LinearProgress color="secondary"></LinearProgress>
            )}
          </ReloadButtonAndProgressContainer>
          {existingStrategies
            .sort((a, b) => b.creationDate.localeCompare(a.creationDate))
            .map((strategy) => (
              <StrategyItem key={strategy.id}>
                <StrategyName>{strategy.name}</StrategyName>
                <StrategyDescription>
                  {strategy.description}
                </StrategyDescription>
                <ButtonContainer>
                  <IconButton
                    size="small"
                    onClick={() => viewStrategy({ id: strategy.id })}
                  >
                    <Launch fontSize="inherit"></Launch>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => copyStrategy(strategy.id)}
                  >
                    <FileCopy fontSize="inherit"></FileCopy>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => deleteStrategy(strategy.id)}
                  >
                    <Delete fontSize="inherit"></Delete>
                  </IconButton>
                </ButtonContainer>
              </StrategyItem>
            ))}
        </Scrollbars>
      </StrategyList>
    </Container>
  );
};

export default ExistingStrategiesList;
