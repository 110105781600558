import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ZoomIn } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import formatPercentage from "../../../../../../Common/Functions/formatPercentage";
import formatPositionType from "../../../../../../Common/Functions/formatPositionType";
import shortenFullIdentifier from "../../../../../../Common/Functions/shortenFullIdentifier";

const Container = styled.div`
  .MuiIconButton-root {
    padding: 6px;
  }
`;

const LiquidationBadge = styled.span`
  background-color: #cfcf40;
  color: black;
  padding: 0 6px;
  font-weight: bold;
`;

const TradeListTable = ({ tradeList, showTradeDetails }) => {
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="sum-area">INFO</TableCell>
              <TableCell className="sum-area">TYPE</TableCell>
              <TableCell className="sum-area" align="right">
                OPENING
              </TableCell>
              <TableCell className="sum-area" align="right">
                CLOSING
              </TableCell>
              <TableCell className="sum-area" align="right">
                PROFIT
              </TableCell>
              <TableCell className="sum-area" align="right">
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tradeList
              .sort((a, b) => a.openTime - b.openTime)
              .map((trade, index) => (
                <TableRow
                  key={`${trade.openTime}-${trade.symbol}-${trade.openingStrategyConfig}`}
                >
                  <TableCell scope="row">
                    #{index + 1}
                    <br></br>
                    {shortenFullIdentifier(trade.symbol)}
                    <br></br>
                    S: {trade.openingStrategyConfig}
                  </TableCell>
                  <TableCell scope="row">
                    {formatPositionType(trade.type)}
                    {trade.opposed && (
                      <div>
                        <em>opposed</em>
                      </div>
                    )}
                    {trade.opposing && (
                      <div>
                        <em>opposing</em>
                      </div>
                    )}
                    {trade.type.indexOf("_CONCURRENT") > 0 && (
                      <div>
                        <em>concurrent</em>
                      </div>
                    )}
                  </TableCell>
                  <TableCell scope="row" align="right">
                    {new Date(trade.openTime).toISOString().split("T")[0]}
                    <br></br>
                    {new Date(trade.openTime)
                      .toISOString()
                      .split("T")[1]
                      .substring(0, 8)}
                    <br></br>
                    {trade.entryPrice.toLocaleString("de-DE")}
                  </TableCell>
                  <TableCell scope="row" align="right">
                    {new Date(trade.closeTime).toISOString().split("T")[0]}
                    <br></br>
                    {new Date(trade.closeTime)
                      .toISOString()
                      .split("T")[1]
                      .substring(0, 8)}
                    <br></br>
                    {trade.closePrice.toLocaleString("de-DE")}
                    <br></br>
                    {trade.closeReason}
                  </TableCell>
                  <TableCell scope="row" align="right">
                    {Math.round(trade.marginAllTime).toLocaleString("de-DE")}
                    <br></br>
                    {formatPercentage(trade.netProfit)}
                    <br></br>
                    {Math.round(
                      trade.marginAllTime * trade.netProfit
                    ).toLocaleString("de-DE")}
                    <br></br>
                    {trade.liquidated && (
                      <LiquidationBadge>LIQ!</LiquidationBadge>
                    )}
                  </TableCell>
                  <TableCell scope="row" align="right">
                    <IconButton onClick={() => showTradeDetails(index)}>
                      <ZoomIn></ZoomIn>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TradeListTable;
