import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin: 12px 0;
  }
`;

const Controls = ({
  setLeverage,
  leverage,
  comission,
  setComission,
  startingMarginBalance,
  setStartingMarginBalance,
  investmentPercentage,
  setInvestmentPercentage,
  concurrentTrades,
  setConcurrentTrades,
  offsetLongShort,
  setOffsetLongShort,
  opposingTradeHandling,
  setOpposingTradeHandling,
  hardStopLoss,
  hardStopDuration,
  maxIpPerSymbol,
  setMaxIpPerSymbol,
  maxConcurrentTrades,
  setMaxConcurrentTrades,
  trendCheckForTrades,
}) => {
  return (
    <Container>
      <FormControl>
        <InputLabel id="startingMarginBalance-label">
          Starting Margin Balance
        </InputLabel>
        <Select
          fullWidth
          color="secondary"
          labelId="startingMarginBalance-label"
          value={startingMarginBalance}
          onChange={(e) => setStartingMarginBalance(e.target.value)}
        >
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={2000}>2000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
          <MenuItem value={15000}>15000</MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="investmentPercentage-label">
          Investment Percentage
        </InputLabel>
        <Select
          fullWidth
          color="secondary"
          labelId="investmentPercentage-label"
          value={investmentPercentage}
          onChange={(e) => setInvestmentPercentage(e.target.value)}
        >
          <MenuItem value={0.01}>0.01</MenuItem>
          <MenuItem value={0.02}>0.02</MenuItem>
          <MenuItem value={0.03}>0.03</MenuItem>
          <MenuItem value={0.04}>0.04</MenuItem>
          <MenuItem value={0.05}>0.05</MenuItem>
          <MenuItem value={0.06}>0.06</MenuItem>
          <MenuItem value={0.07}>0.07</MenuItem>
          <MenuItem value={0.08}>0.08</MenuItem>
          <MenuItem value={0.1}>0.10</MenuItem>
          <MenuItem value={0.11}>0.11</MenuItem>
          <MenuItem value={0.12}>0.12</MenuItem>
          <MenuItem value={0.14}>0.14</MenuItem>
          <MenuItem value={0.16}>0.16</MenuItem>
          <MenuItem value={0.2}>0.20</MenuItem>
          <MenuItem value={0.25}>0.25</MenuItem>
          <MenuItem value={0.33}>0.33</MenuItem>
          <MenuItem value={0.5}>0.50</MenuItem>
          <MenuItem value={1.0}>1.00</MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="leverage-label">Leverage</InputLabel>
        <Select
          fullWidth
          color="secondary"
          labelId="leverage-label"
          value={leverage}
          onChange={(e) => setLeverage(e.target.value)}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={11}>11</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={13}>13</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="comission-label">Comission</InputLabel>
        <Select
          fullWidth
          color="secondary"
          labelId="comission-label"
          value={comission}
          onChange={(e) => setComission(e.target.value)}
        >
          <MenuItem value={0}>0.00%</MenuItem>
          <MenuItem value={0.0005}>0.05%</MenuItem>
          <MenuItem value={0.001}>0.1%</MenuItem>
          <MenuItem value={0.0015}>0.15%</MenuItem>
          <MenuItem value={0.002}>0.2%</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={concurrentTrades}
            onChange={(e) => setConcurrentTrades(e.target.checked)}
            name="concurrentTrades"
          />
        }
        label="Concurrent Trades"
      />
      <FormControlLabel
        control={
          <Switch
            checked={offsetLongShort}
            onChange={(e) => setOffsetLongShort(e.target.checked)}
            name="offsetLongShort"
          />
        }
        label="Offset Long vs. Short"
      />
      <FormControl>
        <InputLabel id="opposingTradeHandling-label">
          Opposing Trade Handling
        </InputLabel>
        <Select
          fullWidth
          color="secondary"
          labelId="opposingTradeHandling-label"
          value={opposingTradeHandling}
          onChange={(e) => setOpposingTradeHandling(e.target.value)}
        >
          <MenuItem value={"KEEP_AND_OPEN"}>
            Keep existing & open new position
          </MenuItem>
          <MenuItem value={"CLOSE_AND_OPEN"}>
            Close existing & do open new position
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="max-ip-per-symbol">Max IP per Symbol</InputLabel>
        <Select
          fullWidth
          color="secondary"
          labelId="max-ip-per-symbol"
          value={maxIpPerSymbol}
          onChange={(e) => setMaxIpPerSymbol(e.target.value)}
        >
          <MenuItem value={0.01}>1%</MenuItem>
          <MenuItem value={0.02}>2%</MenuItem>
          <MenuItem value={0.03}>3%</MenuItem>
          <MenuItem value={0.05}>5%</MenuItem>
          <MenuItem value={0.1}>10%</MenuItem>
          <MenuItem value={0.2}>20%</MenuItem>
          <MenuItem value={0.5}>50%</MenuItem>
          <MenuItem value={1}>100%</MenuItem>
        </Select>
      </FormControl>
      {concurrentTrades && (
        <FormControl>
          <InputLabel id="max-concurrent-trades">
            Max Concurrent Trades
          </InputLabel>
          <Select
            fullWidth
            color="secondary"
            labelId="max-concurrent-trades"
            value={maxConcurrentTrades}
            onChange={(e) => setMaxConcurrentTrades(e.target.value)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={"UNLIMITED"}>UNLIMITED</MenuItem>
          </Select>
        </FormControl>
      )}
      <br></br>
      <span>Hard Stop Loss: {hardStopLoss}</span>
      <br></br>
      <span>Hard Stop Duration: {hardStopDuration}</span>
      <br></br>
      <span>Trend Check threshold: {trendCheckForTrades?.data?.threshold}</span>
      <br></br>
      <span>Trend Check hours: {trendCheckForTrades?.data?.hours}</span>
    </Container>
  );
};

export default Controls;
