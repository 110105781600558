import React from "react";
import styled from "styled-components";
import TradeListItem from "./TradeListItem";
import TradeListLineColumn from "./TradeListLineColumn";

const Container = styled.div`
  margin: 12px;
  padding: 12px;
  background-color: #1b3342;
  border-radius: 4px;
`;

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Time = styled.h4`
  text-align: center;
  margin: 6px;
`;

const TradeListLine = ({ lineData }) => {
  const allSymbols = [];
  const allOpeningStrategyConfigs = [];

  const liveTradesOpening = lineData.trades.filter(
    (t) => t.source === "TRADE" && t.type.indexOf("OPEN") === 0
  );
  const liveTradesClosing = lineData.trades.filter(
    (t) => t.source === "TRADE" && t.type.indexOf("CLOSE") === 0
  );
  const backtestTradesOpening = lineData.trades.filter(
    (t) => t.source === "BACKTEST" && t.type.indexOf("OPEN") === 0
  );
  const backtestTradesClosing = lineData.trades.filter(
    (t) => t.source === "BACKTEST" && t.type.indexOf("CLOSE") === 0
  );

  liveTradesOpening.forEach((t) => {
    allSymbols.push(t.symbol);
    allOpeningStrategyConfigs.push(t.openingStrategyConfig);
  });
  liveTradesClosing.forEach((t) => {
    allSymbols.push(t.symbol);
    allOpeningStrategyConfigs.push(t.openingStrategyConfig);
  });
  backtestTradesOpening.forEach((t) => {
    allSymbols.push(t.symbol);
    allOpeningStrategyConfigs.push(t.openingStrategyConfig);
  });
  backtestTradesClosing.forEach((t) => {
    allSymbols.push(t.symbol);
    allOpeningStrategyConfigs.push(t.openingStrategyConfig);
  });

  const uniqueSymbols = [...new Set(allSymbols)].sort((a, b) =>
    a.localeCompare(b)
  );
  const uniqueOpeningStrategyConfigs = [
    ...new Set(allOpeningStrategyConfigs),
  ].sort((a, b) => a.localeCompare(b));

  const showOpening =
    liveTradesOpening.length > 0 || backtestTradesOpening.length > 0;
  const showClosing =
    liveTradesClosing.length > 0 || backtestTradesClosing.length > 0;

  return (
    <Container>
      <Time>{new Date(lineData.time).toUTCString()}</Time>
      <ColumnContainer>
        <TradeListLineColumn>
          {showClosing && (
            <>
              {uniqueSymbols.map((s) =>
                uniqueOpeningStrategyConfigs.map((c) => {
                  const trade = liveTradesClosing.find(
                    (t) => t.symbol === s && t.openingStrategyConfig === c
                  );
                  if (trade) {
                    return (
                      <TradeListItem
                        trade={trade}
                        key={`${trade.openingStrategyConfig}-${trade.symbol}`}
                        className="big"
                      ></TradeListItem>
                    );
                  } else {
                    return (
                      <div
                        key={`${c}-${s}`}
                        className={
                          backtestTradesClosing.length &&
                          backtestTradesClosing.find(
                            (t) =>
                              t.symbol === s && t.openingStrategyConfig === c
                          )
                            ? "big"
                            : ""
                        }
                      ></div>
                    );
                  }
                })
              )}
            </>
          )}
          {showOpening && (
            <>
              {uniqueSymbols.map((s) =>
                uniqueOpeningStrategyConfigs.map((c) => {
                  const trade = liveTradesOpening.find(
                    (t) => t.symbol === s && t.openingStrategyConfig === c
                  );
                  if (trade) {
                    return (
                      <TradeListItem
                        trade={trade}
                        key={`${trade.openingStrategyConfig}-${trade.symbol}`}
                        className="big"
                      ></TradeListItem>
                    );
                  } else {
                    return (
                      <div
                        key={`${c}-${s}`}
                        className={
                          backtestTradesOpening.length &&
                          backtestTradesOpening.find(
                            (t) =>
                              t.symbol === s && t.openingStrategyConfig === c
                          )
                            ? "big"
                            : ""
                        }
                      ></div>
                    );
                  }
                })
              )}
            </>
          )}
        </TradeListLineColumn>
        <TradeListLineColumn>
          {showClosing && (
            <>
              {uniqueSymbols.map((s) =>
                uniqueOpeningStrategyConfigs.map((c) => {
                  const trade = backtestTradesClosing.find(
                    (t) => t.symbol === s && t.openingStrategyConfig === c
                  );
                  if (trade) {
                    return (
                      <TradeListItem
                        trade={trade}
                        key={`${trade.openingStrategyConfig}-${trade.symbol}`}
                        className="big"
                      ></TradeListItem>
                    );
                  } else {
                    return (
                      <div
                        key={`${c}-${s}`}
                        className={
                          liveTradesClosing.length &&
                          liveTradesClosing.find(
                            (t) =>
                              t.symbol === s && t.openingStrategyConfig === c
                          )
                            ? "big"
                            : ""
                        }
                      ></div>
                    );
                  }
                })
              )}
            </>
          )}
          {showOpening && (
            <>
              {uniqueSymbols.map((s) =>
                uniqueOpeningStrategyConfigs.map((c) => {
                  const trade = backtestTradesOpening.find(
                    (t) => t.symbol === s && t.openingStrategyConfig === c
                  );
                  if (trade) {
                    return (
                      <TradeListItem
                        trade={trade}
                        key={`${trade.openingStrategyConfig}-${trade.symbol}`}
                        className="big"
                      ></TradeListItem>
                    );
                  } else {
                    return (
                      <div
                        key={`${c}-${s}`}
                        className={
                          liveTradesOpening.length &&
                          liveTradesOpening.find(
                            (t) =>
                              t.symbol === s && t.openingStrategyConfig === c
                          )
                            ? "big"
                            : ""
                        }
                      ></div>
                    );
                  }
                })
              )}
            </>
          )}
        </TradeListLineColumn>
      </ColumnContainer>
    </Container>
  );
};

export default TradeListLine;
