import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectStrategyDialog from "../SelectStrategyDialog/SelectStrategyDialog";

const StrategyOverview = () => {
  const [loadingActiveStrategy, setLoadingActiveStrategy] = useState(true);
  const [activeStrategy, setActiveStrategy] = useState(null);
  const [showStrategySelection, setShowStrategySelection] = useState(false);
  const [loadingDisableStrategy, setLoadingDisableStrategy] = useState(false);
  const disableStrategy = async () => {
    setLoadingDisableStrategy(true);
    try {
      await axios.delete(`trading-manager-kucoin/active-strategy`);
      await loadActiveStrategy();
      setLoadingDisableStrategy(false);
    } catch (error) {
      setLoadingDisableStrategy(false);
    }
  };

  const loadActiveStrategy = async () => {
    setLoadingActiveStrategy(true);
    const { data: strategy } = await axios.get(
      `trading-manager-kucoin/active-strategy`
    );
    setLoadingActiveStrategy(false);
    setActiveStrategy(strategy);
  };

  useEffect(() => {
    loadActiveStrategy();
  }, []);
  return (
    <>
      <h2>Strategy and Configuration</h2>
      {loadingActiveStrategy && <div>loadingActiveStrategy</div>}
      {!loadingActiveStrategy && !activeStrategy?.activeStrategy?.strategy && (
        <>
          <div>No active strategy selected</div>
        </>
      )}
      {!loadingActiveStrategy && activeStrategy?.activeStrategy?.strategy && (
        <>
          <div>Selected strategy: {activeStrategy.activeStrategy.strategy}</div>
          <div>{activeStrategy.activeStrategy.selectedSymbols.join(",")}</div>
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        disabled={loadingDisableStrategy}
        onClick={() => setShowStrategySelection(true)}
      >
        Select Strategy
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={loadingDisableStrategy}
        onClick={() => disableStrategy()}
      >
        Disable Strategy
      </Button>
      {loadingDisableStrategy && <div>loadingDisableStrategy</div>}
      {!loadingActiveStrategy && activeStrategy && (
        <SelectStrategyDialog
          visible={showStrategySelection}
          setVisible={setShowStrategySelection}
          initialConfig={{
            symbols: activeStrategy?.activeStrategy?.selectedSymbols,
            strategy: activeStrategy?.activeStrategy?.strategy,
            leverage: activeStrategy?.activeStrategy?.leverage,
            investmentPercentage:
              activeStrategy?.activeStrategy?.investmentPercentage,
            concurrentTrades: activeStrategy?.activeStrategy?.concurrentTrades,
            opposingTradeHandling:
              activeStrategy?.activeStrategy?.opposingTradeHandling,
            hardStopLoss: activeStrategy?.activeStrategy?.hardStopLoss,
            hardStopDuration: activeStrategy?.activeStrategy?.hardStopDuration,
          }}
        ></SelectStrategyDialog>
      )}
    </>
  );
};

export default StrategyOverview;
