import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React, { useState } from "react";
import styled from "styled-components";

const DialogContainer = styled(Dialog)`
  .MuiDialogContent-root {
    overflow-x: hidden;
  }
`;

const CodeContainer = ({ optimizationSteps, getConfigFromId, definitions }) => {
  const [showingCopyCodeForAll, setShowingCopyCodeForAll] = useState(false);
  return (
    <>
      <DialogContainer fullWidth maxWidth="xl" open={showingCopyCodeForAll}>
        <DialogTitle>Code of all strategies for copy paste</DialogTitle>
        <DialogContent>
          <p>
            {JSON.stringify(
              optimizationSteps
                .filter((s) => s.perfectPartner)
                .map((s) => s.perfectPartner)
                .map((item, index) => {
                  return [
                    ...getConfigFromId(item.slice(0, -2), definitions).filter(
                      (c) => c.type !== "id"
                    ),
                    {
                      type: "direction",
                      value:
                        item.substring(item.length - 1) === "L"
                          ? "LONG"
                          : "SHORT",
                    },
                    { type: "id", value: index.toString() },
                  ];
                })
            )}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setShowingCopyCodeForAll(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </DialogContainer>
      <Button
        onClick={() => {
          setShowingCopyCodeForAll(true);
        }}
      >
        <FileCopy></FileCopy>&nbsp;Copy up to here
      </Button>
    </>
  );
};

export default CodeContainer;
