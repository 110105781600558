import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-left: auto;
`;

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000); // update every minute

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = time.toISOString().slice(0, 19).replace("T", " | ");

  return <Container>GMT: {formattedTime}</Container>;
};

export default Clock;
