import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React, { useState } from "react";
import styled from "styled-components";

const DialogContainer = styled(Dialog)`
  .MuiDialogContent-root {
    overflow-x: hidden;
  }
`;

const CopyCode = ({ resultList }) => {
  const [showingCopyCodeForAll, setShowingCopyCodeForAll] = useState(false);
  return (
    <>
      <DialogContainer fullWidth maxWidth="xl" open={showingCopyCodeForAll}>
        <DialogTitle>Code of all strategies for copy paste</DialogTitle>
        <DialogContent>
          <p>[{resultList.map((r) => JSON.stringify(r.config)).join(",")}]</p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setShowingCopyCodeForAll(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </DialogContainer>
      <Button
        onClick={() => {
          setShowingCopyCodeForAll(true);
        }}
      >
        <FileCopy></FileCopy>&nbsp;Copy All Strategies
      </Button>
    </>
  );
};

export default CopyCode;
