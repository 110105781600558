import React from "react";
import styled from "styled-components";
import formatPercentage from "../../../../../Common/Functions/formatPercentage";
import ResultAreaContainer from "../Components/ResultAreaContainer";
import Column from "../Components/SummaryComponents/Column";
import ColumnBody from "../Components/SummaryComponents/ColumnBody";
import ColumnBodyLine from "../Components/SummaryComponents/ColumnBodyLine";
import ColumnBodyLineGroup from "../Components/SummaryComponents/ColumnBodyLineGroup";
import ColumnHeader from "../Components/SummaryComponents/ColumnHeader";
import Label from "../Components/SummaryComponents/Label";
import LabelValuePair from "../Components/SummaryComponents/LabelValuePair";
import Value from "../Components/SummaryComponents/Value";

const Container = styled(ResultAreaContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
`;

const Summary = ({ summary }) => {
  return (
    <Container>
      <Column>
        <ColumnHeader>
          TOTAL: {summary.total.count} Trades in {summary.total.countM} Months (
          {summary.total.count &&
            (summary.total.count / summary.total.countM).toFixed(1)}
          )
        </ColumnHeader>
        <ColumnBody>
          <ColumnBodyLineGroup>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MEA_M: </Label>
                <Value>{formatPercentage(summary.total.meanM)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>SUM_M: </Label>
                <Value>{formatPercentage(summary.total.sumM)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>STD_M: </Label>
                <Value>{formatPercentage(summary.total.stdevM)}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MAX_M: </Label>
                <Value>{formatPercentage(summary.total.maxM)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MIN_M: </Label>
                <Value>{formatPercentage(summary.total.minM)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>WIN_M: </Label>
                <Value>{formatPercentage(summary.total.winM)}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          </ColumnBodyLineGroup>
          <ColumnBodyLineGroup>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MEA_T: </Label>
                <Value>{formatPercentage(summary.total.mean)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>SUM_T: </Label>
                <Value>{formatPercentage(summary.total.sum)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>STD_T: </Label>
                <Value>{formatPercentage(summary.total.stdev)}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
            <ColumnBodyLine>
              <LabelValuePair>
                <Label>MAX_T: </Label>
                <Value>{formatPercentage(summary.total.max)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>MIN_T: </Label>
                <Value>{formatPercentage(summary.total.min)}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>WIN_T: </Label>
                <Value>{formatPercentage(summary.total.win)}</Value>
              </LabelValuePair>
            </ColumnBodyLine>
          </ColumnBodyLineGroup>
        </ColumnBody>
      </Column>
    </Container>
  );
};

export default Summary;
