import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import DetailContainer from "./DetailContainer/DetailContainer";
import { LinearProgress } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ListContainer = styled.div`
  margin: 12px;
  width: 180px;
  max-height: 80vh;
  height: 80vh;
  min-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ListItem = styled.div`
  background-color: #345061;
  padding: 6px;
  border-radius: 4px;
  margin: 6px 0;
  cursor: pointer;
`;

const DetailOuterContainer = styled.div`
  max-height: 90vh;
  overflow-y: auto;
  flex: 1;
`;

let getConfigFromId;
let getDefinitions;
let definitions;

const WelfiCombinationsModule = () => {
  const [loadingCombinerResults, setLoadingCombinerResults] = useState(true);
  const [combinerResults, setCombinerResults] = useState([]);
  const [loadingShownCombinerResult, setLoadingShownCombinerResult] =
    useState(false);
  const [shownCombinerResult, setShownCombinerResult] = useState(null);

  const loadCombinerResults = async () => {
    setLoadingCombinerResults(true);
    const { data: definitionFiles } = await axios.get(
      `welfi-result-manager/welfi-config-data`
    );
    // eslint-disable-next-line no-new-func, no-eval
    getConfigFromId = eval(definitionFiles.getConfigFromId);
    // eslint-disable-next-line no-new-func
    getDefinitions = new Function(definitionFiles.definitions);
    definitions = getDefinitions();
    const { data: results } = await axios.get(
      `welfi-result-manager/welfi-combiner-result-overview/`
    );
    setCombinerResults(results.filter((r) => r.indexOf(".json") > -1));
    setLoadingCombinerResults(false);
  };

  const loadShownCombinerResult = async (combinerResult) => {
    setLoadingShownCombinerResult(true);
    const { data: result } = await axios.get(
      `welfi-result-manager/welfi-combiner-result/`,
      { params: { id: combinerResult } }
    );
    setShownCombinerResult(result);
    setLoadingShownCombinerResult(false);
  };

  useEffect(() => {
    loadCombinerResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ListContainer>
        <h2>Combinations</h2>
        {loadingCombinerResults ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : (
          <>
            {combinerResults.map((combinerResult) => (
              <ListItem
                key={combinerResult}
                onClick={() => loadShownCombinerResult(combinerResult)}
              >
                {combinerResult}
              </ListItem>
            ))}
          </>
        )}
      </ListContainer>
      <DetailOuterContainer>
        {loadingShownCombinerResult ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : shownCombinerResult ? (
          <>
            {shownCombinerResult.combination.length > 0 ? (
              <DetailContainer
                shownCombinerResult={shownCombinerResult}
                getConfigFromId={getConfigFromId}
                definitions={definitions}
              ></DetailContainer>
            ) : (
              <span>No strategies found for this combination</span>
            )}
          </>
        ) : (
          <span>Select a combiner result</span>
        )}
      </DetailOuterContainer>
    </Container>
  );
};

export default WelfiCombinationsModule;
