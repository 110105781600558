import { IconButton, LinearProgress } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AreaContainer from "../../../ControllingModule/Components/AreaContainer";
import AnalysisListItem from "./Components/AnalysisListItem";
import AnalysisListItemCandle from "./Components/AnalysisListItemCandle";
import AnalysisListItemDetails from "./Components/AnalysisListItemDetails";
import AnalysisListItemReason from "./Components/AnalysisListItemReason";
import AnalysisListItemSymbol from "./Components/AnalysisListItemSymbol";
import NoDataHint from "../../../ControllingModule/Components/NoDataHint";
import HeaderContainer from "../../../ControllingModule/Components/HeaderContainer";

const Container = styled.div`
  min-width: 1100px;
  width: 1100px;
`;

const AnalysisList = () => {
  const [loadingAnalysisList, setLoadingAnalysisList] = useState(true);
  const [analysisList, setAnalysisList] = useState([]);

  const loadAnalysisList = async () => {
    setLoadingAnalysisList(true);
    const { data: analysisList } = await axios.get(
      `trading-manager-kucoin/analysis-list`
    );
    console.log(analysisList);
    setLoadingAnalysisList(false);
    setAnalysisList(analysisList);
  };

  useEffect(() => {
    loadAnalysisList();
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <h2>Hourly Analysis Data</h2>
        <IconButton onClick={() => loadAnalysisList()}>
          <Refresh fontSize="inherit"></Refresh>
        </IconButton>
      </HeaderContainer>
      <AreaContainer>
        {loadingAnalysisList ? (
          <LinearProgress color="secondary"></LinearProgress>
        ) : (
          <>
            {analysisList.length ? (
              analysisList.map((item, index) => {
                const decisions = item.result ? JSON.parse(item.result) : [];
                return item.symbol ? (
                  <AnalysisListItem
                    key={`${item.time}-${item.symbol}-${item.type}`}
                    decisions={decisions}
                  >
                    <AnalysisListItemSymbol
                      symbol={item.symbol}
                      time={item.time}
                      type={item.type}
                      decisions={decisions}
                    ></AnalysisListItemSymbol>
                    <AnalysisListItemDetails>
                      <AnalysisListItemCandle
                        label="1H"
                        candle={JSON.parse(item.candle1H)}
                      ></AnalysisListItemCandle>
                      <AnalysisListItemCandle
                        label="4H"
                        candle={JSON.parse(item.candle4H)}
                      ></AnalysisListItemCandle>
                      <AnalysisListItemCandle
                        label="1D"
                        candle={JSON.parse(item.candle1D)}
                      ></AnalysisListItemCandle>
                      <AnalysisListItemCandle
                        label="HA1H"
                        candle={JSON.parse(item.heikinAshis1H)}
                      ></AnalysisListItemCandle>
                      <AnalysisListItemCandle
                        label="HA4H"
                        candle={JSON.parse(item.heikinAshis4H)}
                      ></AnalysisListItemCandle>
                      <AnalysisListItemCandle
                        label="HA1D"
                        candle={JSON.parse(item.heikinAshis1D)}
                      ></AnalysisListItemCandle>
                      <AnalysisListItemReason
                        decisions={decisions}
                      ></AnalysisListItemReason>
                    </AnalysisListItemDetails>
                  </AnalysisListItem>
                ) : (
                  <div key={index}></div>
                );
              })
            ) : (
              <NoDataHint>No Data</NoDataHint>
            )}
          </>
        )}
      </AreaContainer>
    </Container>
  );
};

export default AnalysisList;
