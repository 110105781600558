import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const EquityLog = ({ equityLog }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const data = equityLog.map((e) => {
      return [
        new Date(e.time).getTime(),
        e.availableBalance + e.positionMargin,
      ];
    });
    setChartData(data);
  }, [equityLog]);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
      backgroundColor: "#05161f",
      style: { fontFamily: "Fira Code" },
    },
    xAxis: {
      type: "datetime",
      lineColor: "#333333",
      tickInterval: 1000 * 60 * 60, // one quarter
      tickColor: "#333333",
    },
    yAxis: {
      type: "logarithmic",
      gridLineColor: "#333333",
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        type: "area",
        data: chartData,
        name: "equity",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default EquityLog;
