import styled from "styled-components";

export default styled.div`
  display: flex;
  position: relative;
  button {
    position: absolute;
    top: 3px;
    right: 12px;
  }
`;
